import { CustomIcon } from '@frontend/design-system'
import React, { FunctionComponent } from 'react'
import cs from 'clsx'
import styles from './module.CommitBranch.scss'
import { Link } from '@reach/router'

type CommitBranchProps = {
  branchName: string | null
  projectId: string | null
  hideIcon?: boolean
  buildNumber: number
  displayName?: string
}

export const CommitBranch: FunctionComponent<CommitBranchProps> = ({
  branchName,
  projectId,
  hideIcon = false,
  buildNumber,
  displayName,
}) => {
  if (!branchName) {
    return (
      <div className={styles.container}>
        {!hideIcon && <CustomIcon name="branch" variant="small" />}
        <span className="commit-branch__name">
          <span className="italic">Unknown</span>
        </span>
      </div>
    )
  }

  const branchRedirectUrl = `/projects/${projectId}/branches/${encodeURIComponent(
    branchName
  )}/review?changeRun=${buildNumber}`

  return (
    <div className={styles.container}>
      {!hideIcon && <CustomIcon name="branch" variant="small" />}
      <span className={cs(['commit-branch__name', styles.name])}>
        <Link to={branchRedirectUrl} data-cy="branch-link">
          {displayName ?? branchName}
        </Link>
      </span>
    </div>
  )
}
