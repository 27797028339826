import React, { FunctionComponent, ReactNode, useState } from 'react'
import { ActionMeta, ValueType } from 'react-select/src/types.js'

import { Filter } from './Filter'
import { MultiSelectDropdown } from './MultiSelectDropdown'
import { MultiSelectOptionType } from './types'

interface MultiSelectFilterProps {
  id: string
  customOption?: any
  dropdownClassName?: string
  emptyLabel?: string
  footer?: ReactNode
  getOptionLabel?(option: MultiSelectOptionType): string
  renderSelectedLabel?(selected: MultiSelectOptionType[]): ReactNode
  isLoading?: boolean
  isSearchable?: boolean
  keepOpen?: boolean
  noOptionsMessage?: any
  onChange(
    value: ValueType<MultiSelectOptionType, any>,
    actionMeta?: ActionMeta<MultiSelectOptionType>
  ): void
  onSearch?(input: string, actionMeta?: ActionMeta<MultiSelectOptionType>): void
  onInputChange?(
    input: string,
    actionMeta?: ActionMeta<MultiSelectOptionType>
  ): void
  options: MultiSelectOptionType[]
  placeholder?: string
  selected: MultiSelectOptionType[]
  title: ReactNode
  disabled?: boolean
  filterClassName?: string
}

const MultiSelectFilter: FunctionComponent<MultiSelectFilterProps> = ({
  id,
  customOption,
  dropdownClassName,
  emptyLabel = 'All',
  footer = null,
  getOptionLabel,
  renderSelectedLabel,
  isLoading = false,
  isSearchable = true,
  keepOpen = false,
  noOptionsMessage,
  onChange,
  onInputChange,
  onSearch,
  options,
  placeholder = 'Search...',
  selected,
  title,
  disabled,
  filterClassName,
}) => {
  const [isOpen, toggleOpen] = useState(false)

  const getSelectedLabel = () => {
    if (!selected || selected.length === 0) {
      return emptyLabel
    }

    if (renderSelectedLabel) {
      return renderSelectedLabel(selected)
    }

    return (
      <>
        {getOptionLabel ? getOptionLabel(selected[0]) : selected[0].label}
        {selected.length > 1 && <span>{` +${selected.length - 1}`}</span>}
      </>
    )
  }

  const renderDropDown = () => {
    return (
      <MultiSelectDropdown
        customOption={customOption}
        dropdownClassName={dropdownClassName}
        footer={footer}
        getOptionLabel={getOptionLabel}
        isLoading={isLoading}
        isSearchable={isSearchable}
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        onInputChange={onInputChange}
        onSearch={onSearch}
        options={options}
        placeholder={placeholder}
        selected={selected}
        isOpen={isOpen}
        isMulti
        menuIsOpen
      />
    )
  }

  return (
    <div className="multi-select-filter">
      <Filter
        id={id}
        title={title}
        open={keepOpen || isOpen}
        // @ts-ignore
        onToggle={(open, _, { source }) => {
          toggleOpen(source === 'select' ? true : open)
        }}
        renderSelectedLabel={() => {
          return (
            <span className="filter__selected-label">{getSelectedLabel()}</span>
          )
        }}
        renderDropDown={renderDropDown}
        disabled={disabled}
        className={filterClassName}
      />
    </div>
  )
}

export { MultiSelectFilter }
