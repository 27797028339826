import { TimeIntervalOptions } from '@packages/common/src'
import { DatesUtilsForQueries } from '@packages/data-context/src/utils/dates'
import {
  ProjectContainerQuery,
  ProjectRunDurationsOverRunsDocument,
  ProjectRunDurationsOverRunsQuery,
  ProjectRunDurationsOverRunsQueryVariables,
  ProjectRunDurationsOverTimeDocument,
  ProjectRunDurationsOverTimeQuery,
  ProjectRunDurationsOverTimeQueryVariables,
  TimeIntervalEnum,
} from '~/graphql-codegen-operations.gen'
import { useNavigate } from '@reach/router'
import { parseISO } from 'date-fns'
import fileDownload from 'js-file-download'
import React, { FunctionComponent, useEffect } from 'react'
import DocumentTitle from 'react-document-title'
import {
  formatDateRangeDateISO,
  getQueryStringFromFilters,
  getValuesFromMultiSelectOptions,
} from '~/common/filters'
import { FiltersContainer } from '~/common/filters/FiltersContainer'
import { StackedLayout } from '~/common/stacked-layout'
import { ErrorBoundaryContent } from '~/lib/error-boundary'
import { sendEventCustom } from '~/lib/page-events'
import { downloadAnalyticsCSV, getAnalyticsCSVFileName } from '../analytics-api'
import { DownloadCSV } from '../DownloadCSV'
import { Kpis } from '../Kpis'
import { RunDurationChart } from './RunDurationChart'
import { RunDurationTable } from './RunDurationTable'
import Filters from '../filters/Filters'
import * as analytics from '~/data/projects/analytics/hooks'
import { getKPIs } from './RunDurationKPI'
import { features } from '~/lib/feature-flags'
import { QueryHookOptions, useQuery } from '@apollo/react-hooks'
import { RunDurationDrillDownTable } from './RunDurationTableDrillDown'

type RunDurationAnalyticProps = {
  projectId: string
  project: ProjectContainerQuery['project']
  familyId: string
}

/**
 * This hooks is to be used until we remove the 'analytics-p1' feature flag. Once
 * the feature flag is removed we should be able to directly use `useProjectRunDurationsOverRunsQuery`.
 *
 * @param byBuildAnalytics Feature flag controlling what to bucket by
 * @param options Inputs for the query
 * @returns Expected Query Data based on the feature flag.
 */
const useRunDurationQuery = (
  byBuildAnalytics: boolean,
  options: QueryHookOptions<
    ProjectRunDurationsOverRunsQuery | ProjectRunDurationsOverTimeQuery,
    | ProjectRunDurationsOverRunsQueryVariables
    | ProjectRunDurationsOverTimeQueryVariables
  >
) => {
  const queryDocument = byBuildAnalytics
    ? ProjectRunDurationsOverRunsDocument
    : ProjectRunDurationsOverTimeDocument
  return useQuery<
    ProjectRunDurationsOverRunsQuery | ProjectRunDurationsOverTimeQuery
  >(queryDocument, options)
}

export const RunDurationAnalytic: FunctionComponent<
  RunDurationAnalyticProps
> = ({ projectId, project, familyId }) => {
  const navigate = useNavigate()
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { selected: tags, matchType: tagsMatch } =
    analytics.useTagsFilter(familyId)
  const { timeInterval } = analytics.useTimeIntervalFilter(familyId)
  // const { buildInterval } = analytics.useBuildIntervalFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const { runDurationsOverTime, setRunDurationsOverTime } =
    analytics.useRunDurationsOverTime()

  analytics.useEventTracking('run-duration', 'Run duration', familyId)
  const byBuildAnalytics =
    features.isEnabled('analytics-p1') &&
    features.isEnabled('analytics-p1-run-duration')

  // Don't add the tagsMatch parameter if there is no tags parameter.
  const tagsMatchOrUndefined = getValuesFromMultiSelectOptions(tags || [])
    ? tagsMatch
    : undefined
  const activeFilters = {
    branches: getValuesFromMultiSelectOptions(branches || []),
    tags: getValuesFromMultiSelectOptions(tags || []),
    tagsMatch: tagsMatchOrUndefined,
  }
  const sharedInput = {
    ...activeFilters,
    projectId,
    timeRange: {
      startDate: formatDateRangeDateISO(timeRange?.startDate),
      endDate: formatDateRangeDateISO(timeRange?.endDate),
    },
  }
  const {
    data,
    loading: queryRunning,
    error,
  } = useRunDurationQuery(byBuildAnalytics, {
    variables: {
      input: {
        ...sharedInput,
        ...(byBuildAnalytics
          ? {
              // buildInterval,
              status: ['PASSED', 'FAILED'],
            }
          : { timeInterval }),
      },
    },
  })

  useEffect(() => {
    if (!queryRunning && data) setRunDurationsOverTime(data)
  }, [data, queryRunning, setRunDurationsOverTime])

  async function downloadCSV() {
    const fileName = getAnalyticsCSVFileName('run-duration', timeRange)
    const response = await downloadAnalyticsCSV({
      id: projectId,
      type: 'run-durations',
      params: {
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        timeInterval: (timeInterval &&
          timeInterval.toLowerCase()) as TimeIntervalEnum,
        startDate: formatDateRangeDateISO(timeRange?.startDate),
        endDate: formatDateRangeDateISO(timeRange?.endDate),
      },
    })
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Export', {
      analyticID: 'run-duration',
      label: 'Run duration',
    })

    if (!window.Cypress) {
      /* istanbul ignore next */
      fileDownload(response, `${fileName}.csv`)
    }
  }

  /**
   * Chart Drilldown behavior
   */
  const [focusedNode, setFocusedNode] = React.useState<{
    date: string
    endDate?: string
    count: number
  }>()
  const navigateToRunList = (date: string, endDate?: string) => {
    if (byBuildAnalytics) {
      setFocusedNode({ date, endDate, count: (focusedNode?.count || 0) + 1 })
    } else {
      const timeRangeForRuns = new DatesUtilsForQueries({
        timeRange: {
          startDate: parseISO(date),
          endDate: endDate ? parseISO(endDate) : undefined,
        },
        timeInterval: TimeIntervalOptions[timeInterval!],
      }).datesForFilters

      const usedFilters = {
        branches,
        timeRange: timeRangeForRuns,
        status: [
          { value: 'PASSED', label: 'PASSED' },
          { value: 'FAILED', label: 'FAILED' },
        ],
        tags,
      }
      const updatedQueryString = getQueryStringFromFilters(usedFilters)
      navigate(`/projects/${projectId}/runs${updatedQueryString}`)
    }
  }

  if (error) {
    return (
      <DocumentTitle title="Run Duration">
        <StackedLayout title="Run duration" className="no-content-padding">
          <ErrorBoundaryContent />
        </StackedLayout>
      </DocumentTitle>
    )
  }

  const isLoading = queryRunning || error !== undefined
  const kpis = getKPIs(runDurationsOverTime, queryRunning)

  return (
    <DocumentTitle title="Run Duration">
      <StackedLayout title="Run duration" className="no-content-padding">
        <div className="project-analytics">
          <div className="run-duration-analytic analytic">
            <FiltersContainer
              extra={
                <DownloadCSV analytic="run-durations" onClick={downloadCSV} />
              }
            >
              <Filters
                familyId={familyId}
                branches
                tags
                timeInterval={!byBuildAnalytics}
                // buildInterval={byBuildAnalytics}
                timeRange
                viewerIsOwnerOrAdmin={
                  project.organizationInfo.viewerIsOwnerOrAdmin
                }
              />
            </FiltersContainer>
            {error ? (
              <ErrorBoundaryContent />
            ) : (
              <>
                <RunDurationChart
                  byBuildAnalytics={byBuildAnalytics}
                  data={runDurationsOverTime}
                  isLoading={isLoading}
                  timeInterval={timeInterval}
                  navigateToRunList={navigateToRunList}
                />
                <Kpis kpis={kpis} isLoading={isLoading} />
                {byBuildAnalytics ? (
                  <RunDurationDrillDownTable
                    projectId={projectId}
                    activeFilters={activeFilters}
                    data={
                      runDurationsOverTime as ProjectRunDurationsOverRunsQuery
                    }
                    focusedNode={focusedNode}
                    isLoading={isLoading}
                  />
                ) : (
                  <RunDurationTable
                    data={runDurationsOverTime}
                    isLoading={isLoading}
                    timeInterval={timeInterval}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </StackedLayout>
    </DocumentTitle>
  )
}
