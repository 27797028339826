import type { dbt } from '@packages/types'

type ValueOf<T> = { [K in keyof T]: T[K] }[keyof T]

export type RunStatusesKey = keyof typeof RunStatuses
export type RunStatusesValue = ValueOf<typeof RunStatuses>
export const RunStatuses = {
  RUNNING: 'running',
  ERRORED: 'errored',
  FAILED: 'failed',
  TIMEDOUT: 'timedOut',
  NOTESTS: 'noTests',
  PASSED: 'passed',
  OVERLIMIT: 'overLimit',
  CANCELLED: 'cancelled',
} as const

/**
 * A run group can only be a limited set of statuses
 */
export type RunGroupStatusesKey = keyof typeof RunGroupStatuses
export type RunGroupStatusesValue = ValueOf<typeof RunGroupStatuses>
export const RunGroupStatuses = {
  UNCLAIMED: 'unclaimed',
  RUNNING: 'running',
  ERRORED: 'errored',
  FAILED: 'failed',
  TIMEDOUT: 'timedOut',
  PASSED: 'passed',
  NOTESTS: 'noTests',
  CANCELLED: 'cancelled',
} as const

export type MembershipRolesKey = keyof typeof MembershipRoles
export type MembershipRolesValue = ValueOf<typeof MembershipRoles>
export const MembershipRoles = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MEMBER: 'member',
  VIEWER: 'viewer',
  PROJECT_ADMIN: 'project_admin',
} as const

export type TeamSystemUseKey = keyof typeof MembershipRoles
export type TeamSystemUseValue = ValueOf<typeof MembershipRoles>
export const TeamSystemUses = {
  DEFAULT: 'default',
} as const

export type TestResultStatesKey = keyof typeof TestResultStates
export type TestResultStatesValue = ValueOf<typeof TestResultStates>
export const TestResultStates = {
  PENDING: 'pending',
  SKIPPED: 'skipped',
  FAILED: 'failed',
  PASSED: 'passed',
  UNKNOWN: 'unknown',
  RUNNING: 'running',
  ERRORED: 'errored',
  TIMEDOUT: 'timedOut',
  CANCELLED: 'cancelled',
} as const

export type TestResultTypeKey = keyof typeof TestResultTypes
export type TestResultTypeValue = ValueOf<typeof TestResultTypes>
export const TestResultTypes = {
  E2E: 'e2e',
  COMPONENT: 'component',
} as const

export const InstanceTestInputState = Object.values(
  TestResultStates
) as TestResultStatesValue[]

export type RunInstanceStatusesKey = keyof typeof RunInstanceStatuses
export type RunInstanceStatusesValue = ValueOf<typeof RunInstanceStatuses>
export const RunInstanceStatuses = {
  UNCLAIMED: 'unclaimed',
  RUNNING: 'running',
  ERRORED: 'errored',
  FAILED: 'failed',
  TIMEDOUT: 'timedOut',
  PASSED: 'passed',
  NOTESTS: 'noTests',
  CANCELLED: 'cancelled',
} as const

export type IntervalTypeOptionsKey = keyof typeof IntervalTypeOptions
export type IntervalTypeOptionsValue = ValueOf<typeof IntervalTypeOptions>
export const IntervalTypeOptions = {
  TIME: 'time',
  BUILD: 'build',
} as const

export type DateRangeNameKey = keyof typeof DateRangeName
export type DateRangeNameValue = ValueOf<typeof DateRangeName>
export const DateRangeName = {
  TODAY: 'TODAY',
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_3_MONTHS: 'LAST_3_MONTHS',
  LAST_12_MONTHS: 'LAST_12_MONTHS',
} as const

export type AdditionalTimeRangeNameKey = keyof typeof AdditionalTimeRangeName
export type AdditionalTimeRangeNameValue = ValueOf<
  typeof AdditionalTimeRangeName
>
export const AdditionalTimeRangeName = {
  ALL_TIME: 'ALL_TIME',
  RUN: 'RUN',
  BRANCH: 'BRANCH',
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
} as const

export type TimeIntervalOptionsKey = keyof typeof TimeIntervalOptions
export type TimeIntervalOptionsValue = ValueOf<typeof TimeIntervalOptions>
export const TimeIntervalOptions = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
} as const

export type MetricDeltaChangeDirectionsKey =
  keyof typeof MetricDeltaChangeDirections
export type MetricDeltaChangeDirectionsValue = ValueOf<
  typeof MetricDeltaChangeDirections
>
export const MetricDeltaChangeDirections = {
  UNCHANGED: 'unchanged',
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
} as const

export type ProjectVisibilityKey = keyof typeof ProjectVisibility
export type ProjectVisibilityValue = ValueOf<typeof ProjectVisibility>
export const ProjectVisibility = {
  PUBLIC: 'public',
  PRIVATE: 'private',
} as const

export type PossibleOperatingSystemsKey = keyof typeof PossibleOperatingSystems
export type PossibleOperatingSystemsValue = ValueOf<
  typeof PossibleOperatingSystems
>
export const PossibleOperatingSystems = {
  WINDOWS: 'windows',
  MAC: 'mac',
  LINUX: 'linux',
  UNKNOWN: 'unknown',
} as const

export type CommitStatusCheckStylesKey = keyof typeof CommitStatusCheckStyles
export type CommitStatusCheckStylesValue = ValueOf<
  typeof CommitStatusCheckStyles
>
export const CommitStatusCheckStyles = {
  GROUP: 'group',
  SPEC: 'spec',
} as const

export type SubscriptionStatusKey = keyof typeof SubscriptionStatus
export type SubscriptionStatusValue = ValueOf<typeof SubscriptionStatus>
export const SubscriptionStatus = {
  FUTURE: 'future',
  IN_TRIAL: 'in_trial',
  ACTIVE: 'active',
  NON_RENEWING: 'non_renewing',
  PAUSED: 'paused',
  CANCELLED: 'cancelled',
} as const

export type ProjectOrderFieldKey = keyof typeof ProjectOrderField
export type ProjectOrderFieldValue = ValueOf<typeof ProjectOrderField>
export const ProjectOrderField = {
  ACTIVITY: 'activity',
  NAME: 'name',
} as const

export type OrderDirectionKey = keyof typeof OrderDirection
export type OrderDirectionValue = ValueOf<typeof OrderDirection>
export const OrderDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const

export type TestModificationTypesKey = keyof typeof TestModificationTypes
export type TestModificationTypesValue = ValueOf<typeof TestModificationTypes>
export const TestModificationTypes = {
  NEW: 'NEW',
  MODIFIED_BODY: 'MODIFIED_BODY',
  MODIFIED_HOOKS: 'MODIFIED_HOOKS',
  MODIFIED_BODY_AND_HOOKS: 'MODIFIED_BODY_AND_HOOKS',
} as const

export type InstanceOrdersKey = keyof typeof InstanceOrders
export type InstanceOrdersValue = ValueOf<typeof InstanceOrders>
export const InstanceOrders = {
  EXECUTION_ORDER: 'EXECUTION_ORDER',
  FILENAME: 'FILENAME',
} as const
export const JobResponsibilities = {
  WRITE_APP_CODE: 'WRITE_APP_CODE',
  WRITE_TESTS: 'WRITE_TESTS',
  REVIEW_TEST_RESULTS: 'REVIEW_TEST_RESULTS',
  MANAGE_INFRASTRUCTURE: 'MANAGE_INFRASTRUCTURE',
  LEAD_ENGINEERING_TEAM: 'LEAD_ENGINEERING_TEAM',
  MANAGE_QUALITY: 'MANAGE_QUALITY',
  MANAGE_RELEASES: 'MANAGE_RELEASES',
  ENGINEERING_LEADERSHIP: 'ENGINEERING_LEADERSHIP',
} as const

export type OrganizationIntegrationsKey = keyof typeof OrganizationIntegrations
export type OrganizationIntegrationsValue = ValueOf<
  typeof OrganizationIntegrations
>
export const OrganizationIntegrations = {
  GITHUB: 'GITHUB',
  GITLAB: 'GITLAB',
  SELF_HOSTED_GITLAB: 'SELF_HOSTED_GITLAB',
  SSO: 'SSO',
  SLACK: 'SLACK',
  JIRA: 'JIRA',
  BITBUCKET: 'BITBUCKET',
} as const

export type SlackAppTokenTypesKey = keyof typeof SlackAppTokenTypes
export type SlackAppTokenTypesValue = ValueOf<typeof SlackAppTokenTypes>
export const SlackAppTokenTypes = {
  BOT: 'BOT',
  USER: 'USER',
} as const

export type ViewByOptionsKey = keyof typeof ViewByOptions
export type ViewByOptionsValue = ValueOf<typeof ViewByOptions>
export const ViewByOptions = {
  TEST_CASE: 'TEST_CASE',
  BROWSER: 'BROWSER',
  SPEC: 'SPEC',
  TAG: 'TAG',
  CYPRESS_VERSION: 'CYPRESS_VERSION',
  OS: 'OS',
  BRANCH: 'BRANCH',
} as const

export type CIProvidersKey = keyof typeof CIProviders
export type CIProvidersValue = ValueOf<typeof CIProviders>
export const CIProviders = {
  AWS_CODEBUILD: 'AWS_CODEBUILD',
  AZURE: 'AZURE',
  BITBUCKET: 'BITBUCKET',
  CIRCLECI: 'CIRCLECI',
  GITHUB_ACTIONS: 'GITHUB_ACTIONS',
  GITLAB: 'GITLAB',
  JENKINS: 'JENKINS',
  TRAVIS_CI: 'TRAVIS_CI',
  OTHER: 'OTHER',
  NONE: 'NONE',
  I_DONT_KNOW: 'I_DONT_KNOW',
} as const

export type TestOrchestrationActionsKey = keyof typeof TestOrchestrationActions
export type TestOrchestrationActionsValue = ValueOf<
  typeof TestOrchestrationActions
>
export const TestOrchestrationActions = {
  MUTE: 'MUTE',
} as const

export type ChargebeeSubscriptionKey = keyof typeof ChargebeeSubscription
export type ChargebeeSubscriptionValue = ValueOf<typeof ChargebeeSubscription>
export const ChargebeeSubscription = {
  FREE_SUBSCRIPTION_ID: 'FREE_SUBSCRIPTION_ID',
} as const

export type InstanceActionOptionsKey = keyof typeof InstanceActionOptions
export type InstanceActionOptionsValue = ValueOf<typeof InstanceActionOptions>
export const InstanceActionOptions = {
  CANCELLED_ON_FAILURE: 'CANCELLED_ON_FAILURE',
  CANCELLED_FROM_DASHBOARD: 'CANCELLED_FROM_DASHBOARD',
} as const

export type UsageCalculationKey = keyof typeof UsageCalculation
export type UsageCalculationValue = ValueOf<typeof UsageCalculation>
export const UsageCalculation = {
  V0_PRIVATE_TESTS: 'v0-private-tests',
  V1_ALL_TESTS: 'v1-all-tests',
  V2_ALL_TESTS: 'v2-all-tests',
} as const

export type TestingTypesKey = keyof typeof TestingTypes
export type TestingTypesValue = ValueOf<typeof TestingTypes>
export const TestingTypes = {
  E2E: 'e2e',
  COMPONENT: 'component',
} as const

export type GithubCommitStatusCheckStatusValue = ValueOf<
  typeof GithubCommitStatusCheckStatus
>
export const GithubCommitStatusCheckStatus = {
  ERROR: 'error',
  PENDING: 'pending',
  FAILURE: 'failure',
  SUCCESS: 'success',
} as const

export const TestsForReviewViewBy = {
  FAILED: 'FAILED',
  FLAKY: 'FLAKY',
  MODIFIED: 'MODIFIED',
} as const

export const TestsForReviewSortBy = {
  STATUS: 'status',
} as const

export type ProtocolCaptureStatusKey = keyof typeof ProtocolCaptureStatus
export type ProtocolCaptureStatusValue = ValueOf<typeof ProtocolCaptureStatus>
export const ProtocolCaptureStatus = {
  AWAITING_UPLOAD: 'awaiting_upload',
  PENDING: 'pending',
  AVAILABLE: 'available',
  ERRORED: 'errored',
} as const

export type ProtocolCaptureErrorKey = keyof typeof ProtocolCaptureError
export type ProtocolCaptureErrorValue = ValueOf<typeof ProtocolCaptureError>
export const ProtocolCaptureError = {
  UPLOAD: 'upload',
  POST_PROCESS: 'post_process',
  TOO_LARGE: 'too_large',
} as const

export const DiscoveryCompactionRunStatus = {
  PROCESSING_FAILED: 'processing_failed',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  PROCESSED_WITH_ERRORS: 'processed_with_errors',
} as const

export const ReportIssuesStatus = {
  MISSING_PROTOCOL_DATA: 'missing_protocol_data',
  TIMEDOUT: 'timedOut',
  CANCELLED: 'cancelled',
} as const

export type InitialStrategyKey = keyof typeof InitialStrategy
export type InitialStrategyValue = ValueOf<typeof InitialStrategy>
export const InitialStrategy = {
  NONE: 'NONE',
  BURN_IN: 'BURN_IN',
  RETRY: 'RETRY',
} as const

export type RecordingActionKey = keyof typeof RecordingAction
export type RecordingActionValue = ValueOf<typeof RecordingAction>
export const RecordingAction = {
  BURN_IN: 'BURN_IN',
  SKIP: 'SKIP',
  MUTE: 'MUTE',
} as const

export type TagsMatchKey = keyof typeof TagsMatch
export type TagsMatchValue = ValueOf<typeof TagsMatch>
export const TagsMatch = {
  ANY: 'ANY',
  ALL: 'ALL',
} as const

export const ORDERED_RELEASE_TAGS: Array<
  NonNullable<dbt.AppQualityConfigSchemas['releaseTag']>
> = ['main', 'canary', 'cypress']

export type OnboardingChecklistStatusKey =
  keyof typeof OnboardingChecklistStatus
export type OnboardingChecklistStatusValue = ValueOf<
  typeof OnboardingChecklistStatus
>
export const OnboardingChecklistStatus = {
  COMPLETED: 'completed',
  PENDING: 'pending',
  SKIPPED: 'skipped',
  COMPLETED_BEFORE_TRIAL: 'completed_before_trial',
} as const
