import {
  CustomIcon,
  Icon,
  SectionHeading,
  TestResultModificationTypeBadge,
} from '@frontend/design-system'
import { remove } from 'lodash'
import React, { FunctionComponent, useState } from 'react'
import {
  TestModificationTypeEnum,
  TestResultModificationScopeEnum,
  TimeRangeInput,
  useEarliestRunDateQuery,
  EarliestRunDateQuery,
} from '~/graphql-codegen-operations.gen'
import { Filter } from '../Filter'
import { TimeRange } from '../TimeRangeFilter'
import { timeRangeAsDates } from '../util'
import {
  createStaticRanges,
  getUTCDateAsLocalDate,
  staticUTCDates,
} from '~/lib/utils'
import { endOfDay, startOfDay, subDays } from 'date-fns'
import { getDateRangeLabel, TimeRangeDates } from '~/lib/utils-ts'
interface TestResultModificationFilterProps {
  id: string
  projectId: string
  commitBranch: string
  integrationBranch?: string | null
  endDate: Date
  dateRangeId: string | null
  selectedDateRange: TimeRangeDates
  selectedModificationTypes: TestModificationTypeEnum[] | null
  onModificationTypeChange(value: TestModificationTypeEnum[]): void
  onDateRangeSelect(
    dateRange: TimeRangeInput,
    scope: TestResultModificationScopeEnum | null
  ): void
}

export const ALL_TEST_RESULT_MODIFICATION_OPTIONS = [
  'NEW',
  'MODIFIED_BODY',
  'MODIFIED_HOOKS',
  'MODIFIED_BODY_AND_HOOKS',
] as const

interface TestResultModificationOptionProps {
  modificationType: TestModificationTypeEnum
  isSelected: boolean
  onClick(): void
}

const TestResultModificationOption = ({
  isSelected,
  modificationType,
  onClick,
}: TestResultModificationOptionProps) => {
  return (
    <button onClick={onClick} className="test-result-modification-types__type">
      {isSelected ? (
        <Icon name="check-square" className="filter__checkbox fa-lg primary" />
      ) : (
        <Icon name="square-o" className="filter__checkbox fa-lg" />
      )}
      <TestResultModificationTypeBadge modificationType={modificationType} />
    </button>
  )
}

export const getFormattedEarliestRunDate = (query?: EarliestRunDateQuery) => {
  const createdAt = query?.project.earliestRunDate
  return createdAt ? startOfDay(new Date(createdAt).getTime()) : null
}

export const TestResultModificationFilter: FunctionComponent<
  TestResultModificationFilterProps
> = ({
  id,
  projectId,
  commitBranch,
  integrationBranch,
  dateRangeId,
  selectedDateRange,
  selectedModificationTypes,
  onModificationTypeChange,
  onDateRangeSelect,
  endDate,
}) => {
  const [isOpen, toggleOpen] = useState(false)
  const isSelectedByDefault = selectedModificationTypes === null

  const { data: earliestCommitBranchRunData } = useEarliestRunDateQuery({
    variables: {
      id: projectId,
      branches: [commitBranch],
    },
    skip: !commitBranch,
  })
  const { data: earliestModifiedRunData } = useEarliestRunDateQuery({
    variables: {
      id: projectId,
      branches: [commitBranch, integrationBranch!],
    },
    skip: !commitBranch || !integrationBranch,
  })

  const earliestCommitBranchRunDateStartOfDay = getFormattedEarliestRunDate(
    earliestCommitBranchRunData
  )
  const earliestModifiedDateStartOfDay = getFormattedEarliestRunDate(
    earliestModifiedRunData || earliestCommitBranchRunData
  )

  const renderDropdownContent = () => {
    const onClick = ({ options }: { options: TestModificationTypeEnum[] }) => {
      const allOptions = [...ALL_TEST_RESULT_MODIFICATION_OPTIONS]
      const selected = selectedModificationTypes
        ? [...selectedModificationTypes]
        : []

      const newOptions = new Set(options)

      if (isSelectedByDefault) {
        remove(allOptions, (modificationOption) =>
          newOptions.has(modificationOption)
        )
        onModificationTypeChange(allOptions)
        return
      }

      const isOptionSelected = selected.some((selectedOption) =>
        newOptions.has(selectedOption)
      )

      if (isOptionSelected) {
        remove(selected, (modificationOption) =>
          newOptions.has(modificationOption)
        )
        onModificationTypeChange(selected)
        return
      }

      onModificationTypeChange([...selected, ...options])
    }

    return (
      <div className="test-result-modification-types__container">
        <p>Include</p>
        <div>
          {[
            {
              isSelected:
                isSelectedByDefault ||
                (selectedModificationTypes || []).some(
                  (selectedOption) => selectedOption === 'NEW'
                ),
              modificationType: 'NEW' as const,
              onClick() {
                onClick({
                  options: ['NEW'],
                })
              },
            },
            {
              isSelected:
                isSelectedByDefault ||
                (selectedModificationTypes || []).some(
                  (selectedOption) => selectedOption === 'MODIFIED_BODY'
                ),
              modificationType: 'MODIFIED_BODY' as const,
              onClick() {
                onClick({
                  options: [
                    'MODIFIED_BODY',
                    'MODIFIED_BODY_AND_HOOKS',
                    'MODIFIED_HOOKS',
                  ],
                })
              },
            },
          ].map((option, idx) => (
            <TestResultModificationOption key={idx} {...option} />
          ))}
        </div>
      </div>
    )
  }

  const endDateUTC = endOfDay(
    getUTCDateAsLocalDate(new Date(endDate).getTime())
  )

  const dateRangeOptions = [
    {
      label: 'This Run',
      range: () => ({
        id: 'RUN',
        startDate: startOfDay(endDateUTC),
        endDate: endDateUTC,
      }),
    },
    {
      label: '24 Hours Prior to this Run',
      range: () => ({
        id: 'DAY',
        startDate: startOfDay(subDays(endDateUTC, 1)),
        endDate: endDateUTC,
      }),
    },
    {
      label: 'Week Prior to this Run',
      range: () => ({
        id: 'WEEK',
        startDate: startOfDay(subDays(endDateUTC, 7)),
        endDate: endDateUTC,
      }),
    },
    {
      label: 'Month Prior to this Run',
      range: () => ({
        id: 'MONTH',
        startDate: startOfDay(subDays(endDateUTC, 30)),
        endDate: endDateUTC,
      }),
    },
    {
      label: 'All Time',
      range: () => ({
        id: 'ALL_TIME',
        startDate: staticUTCDates.getEpoch(),
        endDate: staticUTCDates.getEndOfEpoch(),
      }),
    },
  ]

  if (earliestCommitBranchRunDateStartOfDay) {
    dateRangeOptions.splice(1, 0, {
      label: 'This Branch',
      range: () => ({
        id: 'BRANCH',
        startDate: earliestCommitBranchRunDateStartOfDay,
        endDate: endDateUTC,
      }),
    })
  }

  const staticRanges = createStaticRanges(dateRangeOptions)

  const value = {
    ...timeRangeAsDates({
      // Ensure dates are date + time
      startDate: startOfDay(
        getUTCDateAsLocalDate(new Date(selectedDateRange.startDate).getTime())
      ),
      endDate:
        selectedDateRange.endDate &&
        endOfDay(
          getUTCDateAsLocalDate(new Date(selectedDateRange.endDate).getTime())
        ),
    }),
    id: dateRangeId ?? undefined,
  }

  const renderDropDown = () => {
    return (
      <>
        <SectionHeading title="Last modified" />
        <TimeRange
          dateRangeOptions={staticRanges}
          value={value}
          dateRangeId={dateRangeId}
          onSelect={(dateRange) => {
            const scope = (() => {
              const isRunScope = dateRange.id === 'run'
              const isBranchScope = dateRange.id === 'branch'

              if (isRunScope) {
                return 'RUN'
              }

              if (isBranchScope) {
                return 'BRANCH'
              }

              return null
            })()

            onDateRangeSelect(
              {
                ...dateRange,
                id: dateRange.id?.toUpperCase(),
              },
              scope
            )
          }}
          minDate={earliestModifiedDateStartOfDay}
          maxDate={endDateUTC}
        />
        {renderDropdownContent()}
      </>
    )
  }

  const renderSelectedLabel = () => {
    const label =
      getDateRangeLabel(staticRanges, value as Omit<TimeRangeDates, 'id'>) ===
      'All Time'
        ? 'Last Modified'
        : getDateRangeLabel(staticRanges, value)

    return <span className="filter__selected-label">{label}</span>
  }

  return (
    <Filter
      id={id}
      open={isOpen}
      title={<CustomIcon name="last-modified" alt="Last modified" />}
      // @ts-ignore
      onToggle={(open: boolean, _, { source }: { source: string }) =>
        toggleOpen(source === 'select' ? true : open)
      }
      renderSelectedLabel={renderSelectedLabel}
      renderDropDown={renderDropDown}
    />
  )
}
