import { Feature, TestFeature } from '@packages/feature-flags'

declare global {
  interface Window {
    __featuresConfig: string
    __featuresByProjects: string
    __globallyEnabledFeatures: string
    __testFeatures: Record<Feature, boolean>
    __serverVersion: string
    __features: typeof features | undefined
  }
}

const featuresConfig: Record<Feature, boolean> =
  window.__testFeatures ||
  (window.__featuresConfig ? JSON.parse(window.__featuresConfig) : {})

const globallyEnabledFeatures: Record<Feature, boolean> =
  window.__globallyEnabledFeatures
    ? JSON.parse(window.__globallyEnabledFeatures)
    : {}

const featuresOnProjects: Record<Feature, string[]> =
  window.__featuresByProjects ? JSON.parse(window.__featuresByProjects) : {}

// TODO load and pass dynamic feature settings from db
export const features = {
  isEnabled: (
    feature: Feature | TestFeature,
    info?: {
      projectId: string // project "slug", which we expose as "id" in gql
    }
  ): boolean => {
    return Boolean(
      featuresConfig[feature] ||
        featuresOnProjects[feature]?.includes(info?.projectId)
    )
  },
  isGloballyEnabled: (...features: Array<Feature | TestFeature>): boolean => {
    return features.every((feature) => globallyEnabledFeatures[feature])
  },
  update: (feature: Feature | TestFeature, isEnabled: boolean) => {
    featuresConfig[feature] = isEnabled
  },
}

if (window.Cypress) {
  window.__features = features
}
