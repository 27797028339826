import { CustomIcon, palette } from '@frontend/design-system'
import { isSameDay, min, max } from 'date-fns'
import React, {
  FunctionComponent,
  ReactNode,
  SyntheticEvent,
  useState,
} from 'react'
import { DateRangePicker } from 'react-date-range'
import {
  allTimeUTCDateRange,
  staticUTCDates,
  staticUTCDateRanges,
  todayUTCDateRange,
  formatDate,
} from '~/lib/utils'
import { Filter } from './Filter'
import { formatDateRangeDate } from './util'
import { getDateRangeLabel, TimeRangeDates } from '~/lib/utils-ts'

type onSelect = (eventKey: any, e?: SyntheticEvent<{}>) => void

interface StaticDateRange {
  label: string
  isSelected: (range: TimeRangeDates) => boolean
  range: () => TimeRangeDates
}

interface TimeRangeProps {
  value: Omit<TimeRangeDates, 'id'>
  dateRangeOptions?: StaticDateRange[]
  onSelect: onSelect
  dateRangeId?: string | null
  minDate?: Date | null
  maxDate?: Date | null
}

/**
 * Ensure that the TimeRangeFilter value is normalized to match the range id.
 *
 * @param timeRange The TimeRangeFilter value.
 * @param ranges All possible ranges for TimeRangeFilter.
 * @returns A date range normalized for TimeRangeFilter.
 */
const normalizeDateRangeForValue = (
  timeRange: TimeRangeDates,
  ranges: StaticDateRange[]
): Omit<TimeRangeDates, 'id'> => {
  let startDate = timeRange.startDate
  let endDate = timeRange.endDate
  const timeRangeId = timeRange.id?.toUpperCase()

  // The timeRangeId must match the date range.
  if (timeRangeId) {
    for (const range of ranges) {
      if (range.isSelected(timeRange)) {
        const foundTimeRange = range.range()
        startDate = foundTimeRange.startDate
        endDate = foundTimeRange.endDate
        break
      }
    }
  }

  // Don't return id because it will not be needed for TimeRange.
  return {
    startDate,
    endDate,
  }
}

export const TimeRange: FunctionComponent<TimeRangeProps> = ({
  dateRangeOptions,
  value,
  dateRangeId,
  onSelect,
  minDate,
  maxDate,
}) => {
  minDate = minDate ?? staticUTCDates.getEpoch()
  maxDate = maxDate ?? staticUTCDates.getEndOfToday()

  const onChange = (ranges: { timeRange: TimeRangeDates }) => {
    const startDate = formatDateRangeDate(ranges.timeRange.startDate)
    const endDate = formatDateRangeDate(ranges.timeRange.endDate)

    // If there is an id matching our date range always return it.
    const timeRangeId = findIdForDates(startDate as Date, endDate)

    const hasSameDateRangeId = dateRangeId === timeRangeId

    if (
      hasSameDateRangeId &&
      isSameDay(formatDate(startDate), formatDate(value.startDate)) &&
      isSameDay(formatDate(endDate), formatDate(value.endDate))
    ) {
      return
    }

    onSelect({
      startDate,
      endDate,
      id: hasSameDateRangeId ? null : timeRangeId,
    })
  }

  const findIdForDates = (startDate: Date, endDate?: Date | null) => {
    if (startDate && endDate && dateRangeOptions) {
      for (const dateRangeOption of dateRangeOptions) {
        const range = dateRangeOption.range()
        if (
          isSameDay(formatDate(startDate), formatDate(range.startDate)) &&
          isSameDay(formatDate(endDate), formatDate(range.endDate))
        ) {
          return range.id
        }
      }
    }

    return null
  }

  // Normalize our date range so that the dates match the id.
  let normalizedValue = value
  if (dateRangeOptions) {
    normalizedValue = normalizeDateRangeForValue(value, dateRangeOptions)
  }

  return (
    <DateRangePicker
      showDateDisplay={false}
      inputRanges={[]}
      ranges={[
        {
          ...normalizedValue,
          color: palette.indigo600,
          key: 'timeRange',
        },
      ]}
      staticRanges={dateRangeOptions}
      shownDate={maxDate}
      minDate={min([minDate, maxDate])}
      maxDate={max([minDate, maxDate])}
      onChange={onChange}
    />
  )
}

interface TimeRangeFilterProps extends TimeRangeProps {
  id: string
  title?: string
  renderDropdownContent?(): ReactNode
  shouldIncludeToday?: boolean
  shouldIncludeAllTime?: boolean
}

export const TimeRangeFilter: FunctionComponent<TimeRangeFilterProps> = ({
  id,
  value,
  onSelect,
  dateRangeOptions,
  title = 'Time Range',
  dateRangeId,
  minDate,
  maxDate,
  renderDropdownContent = () => null,
  shouldIncludeToday = false,
  shouldIncludeAllTime = false,
}) => {
  const [isOpen, toggleOpen] = useState(false)

  const staticRanges: StaticDateRange[] = [
    ...(shouldIncludeToday ? todayUTCDateRange : []),
    ...(dateRangeOptions ? dateRangeOptions : staticUTCDateRanges),
    ...(shouldIncludeAllTime ? allTimeUTCDateRange : []),
  ]

  // Normalize our date range so that the dates match the id.
  const normalizedValue = normalizeDateRangeForValue(value, staticRanges)

  const renderDropDown = () => {
    return (
      <>
        <TimeRange
          dateRangeOptions={staticRanges}
          value={value}
          dateRangeId={dateRangeId}
          onSelect={onSelect}
          minDate={minDate}
          maxDate={maxDate}
        />
        {renderDropdownContent()}
      </>
    )
  }

  const renderSelectedLabel = () => {
    const label = getDateRangeLabel(staticRanges, normalizedValue)

    return <span className="filter__selected-label">{label}</span>
  }

  return (
    <Filter
      id={id}
      open={isOpen}
      emptyLabel={title}
      title={<CustomIcon name="date" alt="Time range" />}
      // @ts-ignore
      onToggle={(open: boolean, _, { source }: { source: string }) =>
        toggleOpen(source === 'select' ? true : open)
      }
      renderSelectedLabel={renderSelectedLabel}
      renderDropDown={renderDropDown}
    />
  )
}
