import React, { FunctionComponent, ReactNode, useState, useEffect } from 'react'
import { IconObjectTag } from '@cypress-design/react-icon'
import {
  Button,
  FilterEmptyState,
  Icon,
  Label,
  OverlayTrigger,
  Popover,
  Radio,
  Tag,
} from '@frontend/design-system'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import { components } from 'react-select'

import { ColorPickerPopover } from '~/common/color-picker-popover'
import { MultiSelectFilter } from '~/common/filters'
import { MultiSelectOptionType } from '~/common/filters/types'
import {
  TagsMatchEnum,
  useProjectTagsQuery,
  useSetTagColorMutation,
} from '~/graphql-codegen-operations.gen'
import iconStyles from '~/common/icon/module.ReactIcon.scss'

interface ProjectTagsFilterOptions {
  tagsMatch?: TagsMatchEnum
}

type ProjectTagsFilterProps = {
  id: string
  onChange: any
  projectId: string
  selected: MultiSelectOptionType<string, { color: string }>[]
  shouldRefetch?: boolean
  filterOptions?: ProjectTagsFilterOptions
  withFilterOptions?: boolean // if the project tag should use and display to user ANY/ALL filter options
  disabled?: boolean
  disableEdit?: boolean
}

function renderLabel(
  option: MultiSelectOptionType<string, { color: string }>
): string {
  const label = (
    <Tag backgroundColor={option.labelProperties!.color}>{option.label}</Tag>
  ) as unknown

  return label as string
}

const TagIcon = () => {
  return (
    <IconObjectTag
      size="16"
      fillColor="gray-50"
      strokeColor="gray-500"
      strokeWidth="1"
      className={iconStyles.strokeWidthThin}
    />
  )
}

export const ProjectTagsFilter: FunctionComponent<ProjectTagsFilterProps> =
  React.memo(
    ({
      id,
      onChange,
      projectId,
      selected,
      shouldRefetch,
      filterOptions = {
        tagsMatch: 'ANY',
      },
      withFilterOptions = true,
      disabled,
      disableEdit,
    }) => {
      const [tagsSearch, setTagsSearch] = useState('')
      const [isLoading, updateIsLoading] = useState(false)
      const [editingTags, editTags] = useState(false)
      const [setTagColorMutation] = useSetTagColorMutation()

      const { data, refetch } = useProjectTagsQuery({
        variables: {
          id: projectId,
          tagsSearch,
        },
        onCompleted() {
          updateIsLoading(false)
        },
      })

      useEffect(() => {
        if (shouldRefetch) {
          refetch()
        }
      }, [refetch, shouldRefetch])

      const Footer = () => {
        if (tagOptions.length < 1) {
          return null
        }
        return (
          <div className="project-tags-filter--footer">
            {editingTags ? (
              <Button bsStyle="link" onClick={() => editTags(false)}>
                Done editing
              </Button>
            ) : (
              <Button
                bsStyle="link"
                onClick={() => editTags(true)}
                disabled={disableEdit === true}
              >
                Edit tags
              </Button>
            )}
          </div>
        )
      }

      let tagOptions: MultiSelectOptionType[] = data
        ? data.project.tags.nodes.map((tag) => {
            return {
              disabled: editingTags,
              label: tag.name,
              labelProperties: { color: tag.color },
              suggested: false,
              value: tag.name,
            }
          })
        : []

      tagOptions = _.unionBy(selected, tagOptions, 'value')

      const unselectedItems: MultiSelectOptionType[] = _.difference(
        tagOptions,
        selected
      )

      const onSelectColor = (tag: string, color: string): void => {
        setTagColorMutation({
          variables: {
            projectId,
            name: tag,
            color,
          },
          refetchQueries: ['ProjectTags'],
        }).then(() => {
          const updatedSelectedOptions = selected.map((option) => {
            if (option.value === tag) {
              return {
                ...option,
                labelProperties: { color },
              }
            }

            return option
          })

          onChange(updatedSelectedOptions)
        })
      }

      const onSelectFilterMatchOption = (
        filterMatchOption: TagsMatchEnum
      ): void => {
        onChange(selected, filterMatchOption)
      }

      const FilterOptionsHeader: FunctionComponent = () => {
        return <div className="tags-filter--header h6">Filter Options</div>
      }

      const SelectedItemsHeader: FunctionComponent = () => {
        return (
          <div className="tags-filter--header h6">
            Selected
            <Button bsStyle="link" onClick={() => onChange([])}>
              Clear
            </Button>
          </div>
        )
      }

      const UnselectedItemsHeader: FunctionComponent = () => {
        if (tagsSearch) {
          return (
            <div className="tags-filter--header unselected h6">
              Search results
            </div>
          )
        }

        return (
          <div className="tags-filter--header unselected h6">Recently used</div>
        )
      }

      const customOption = (props): ReactNode => {
        if (editingTags) {
          return (
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              rootClose
              overlay={
                <Popover id="color-picker-popover">
                  <ColorPickerPopover
                    onSelect={(color) => onSelectColor(props.data.label, color)}
                  />
                </Popover>
              }
            >
              <div className="project-tags-filter--editing-option select__option">
                {props.data.label}
                <div
                  className="color-picker-color-sample pull-right"
                  style={{ backgroundColor: props.data.labelProperties.color }}
                />
              </div>
            </OverlayTrigger>
          )
        }

        return (
          <>
            {withFilterOptions &&
              selected.length > 1 &&
              props.value === selected[0].value && (
                <>
                  <FilterOptionsHeader />

                  <div className="select__option">
                    <Radio
                      isChecked={filterOptions.tagsMatch === 'ANY'}
                      label="Match Any"
                      onChange={() => onSelectFilterMatchOption('ANY')}
                      value="matchAny"
                    />
                  </div>
                  <div className="select__option">
                    <Radio
                      isChecked={filterOptions.tagsMatch === 'ALL'}
                      label="Match All"
                      onChange={() => onSelectFilterMatchOption('ALL')}
                      value="matchAll"
                    />
                  </div>
                </>
              )}
            {selected.length > 0 && props.value === selected[0].value && (
              <SelectedItemsHeader />
            )}
            {unselectedItems.length > 0 &&
              unselectedItems[0].value === props.value && (
                <UnselectedItemsHeader />
              )}
            <components.Option {...props}>
              <span>
                {props.isSelected ? (
                  <Icon
                    name="check-square"
                    className="filter__checkbox fa-lg primary"
                  />
                ) : (
                  <Icon name="square-o" className="filter__checkbox fa-lg" />
                )}{' '}
                <Tag backgroundColor={props.data.labelProperties.color}>
                  <Highlighter
                    searchWords={[tagsSearch]}
                    textToHighlight={props.data.label}
                  />
                </Tag>{' '}
              </span>
              {props.data.suggested && <Label bsStyle="default">default</Label>}
            </components.Option>
          </>
        )
      }

      const NoTagsMessage = () => {
        return (
          <FilterEmptyState
            filter="tag"
            icon={<TagIcon />}
            info="Find runs more quickly by adding tags."
            searchQuery={tagsSearch}
            url="http://on.cypress.io/run-tags"
          />
        )
      }

      const label = 'Tag: '
      const emptyLabel = `${label}Any`
      const renderSelectedLabel = (selected): React.ReactNode => {
        const selectedOption = selected[0]
        return (
          <span>
            {label}
            <Tag backgroundColor={selectedOption.labelProperties!.color}>
              {selectedOption.label}
            </Tag>
            {selected.length > 1 && <span>{` +${selected.length - 1}`}</span>}
          </span>
        )
      }

      return (
        <div
          data-cy="tags-filter"
          data-pendo="tags-filter"
          className="tags-filter multi-select-filter-with-categories"
        >
          <MultiSelectFilter
            id={id}
            title={<TagIcon />}
            emptyLabel={emptyLabel}
            dropdownClassName="project-tags-filter"
            placeholder="Search for a tag..."
            customOption={customOption}
            getOptionLabel={renderLabel}
            isLoading={isLoading}
            keepOpen={editingTags}
            noOptionsMessage={NoTagsMessage}
            onChange={(
              tags: MultiSelectOptionType<string, { color: string }>[]
            ) => {
              onChange(tags, filterOptions.tagsMatch)
            }}
            onInputChange={() => updateIsLoading(true)}
            onSearch={setTagsSearch}
            options={tagOptions}
            selected={selected}
            renderSelectedLabel={renderSelectedLabel}
            footer={<Footer />}
            disabled={disabled}
          />
        </div>
      )
    }
  )
