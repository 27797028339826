import { LoaderPage } from '~/common/loader'
import { RouteComponentProps, Router } from '@reach/router'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import DocumentTitle from 'react-document-title'

import { chunks } from '~/chunks'
import NotFound from '~/not-found'
import { PastRunDurationPage } from '~/project-analytics/past-run-duration/PastRunDurationPage'
import { RunDurationAnalytic } from '~/project-analytics/run-duration/RunDurationAnalytic'
import { RunsOverTimeAnalytic } from '~/project-analytics/runs-over-time/RunsOverTimeAnalytic'
import { TestSuiteSizeAnalytic } from '~/project-analytics/test-suite-size/TestSuiteSizeAnalytic'
import { TopFailuresAnalytic } from '~/project-analytics/top-failures/TopFailuresAnalytic'
import { SlowestTestsAnalytic } from '~/project-analytics/slowest-tests/SlowestTestsAnalytic'
import { MostCommonErrorsAnalytic } from '~/project-analytics/most-common-errors/MostCommonErrorsAnalytic'
import { FlakyTestsAnalytic } from '~/project-analytics/flaky-tests/FlakyTestsAnalytic'
import { ProjectAnalytics } from '~/project-analytics/ProjectAnalytics'
import { getProjectAnalyticsFamilyId } from '~/data/projects/analytics/hooks'
import {
  ProjectContainerQuery,
  useAqFeatureFlagsQuery,
  useProjectContainerQuery,
} from '~/graphql-codegen-operations.gen'

import { RunContainer } from './RunContainer'
import { AnalyticsGroupOneContainer } from '~/project-analytics/containers/AnalyticsGroupOneContainer'
import { AnalyticsGroupTwoContainer } from '~/project-analytics/containers/AnalyticsGroupTwoContainer'
import './ProjectContainer.scss'
import { AQFeatureFlagsContext } from '~/common/intelligence/feature-flags/AQFeatureFlagsContext'

interface ProjectContainerProps
  extends RouteComponentProps<{ projectId: string }> {}

export const ProjectContainer: React.FC<ProjectContainerProps> = ({
  projectId,
  navigate,
}) => {
  const { data, error } = useProjectContainerQuery({
    variables: {
      id: projectId!,
    },
    skip: !projectId,
  })

  const { data: aqFeatureFlagsData, loading: aqFeatureFlagsLoading } =
    useAqFeatureFlagsQuery({
      variables: { projectId: projectId! },
      skip: !projectId,
    })

  const {
    uiCovEnabled = false,
    accessibilityEnabled = false,
    accessibilityAxeRuleStatuses = false,
  } = aqFeatureFlagsData?.project?.aqFeatureFlags || {}

  const aqFeatureFlags = React.useMemo(
    () => ({
      uiCovEnabled,
      accessibilityEnabled,
      accessibilityAxeRuleStatuses,
    }),
    [uiCovEnabled, accessibilityEnabled, accessibilityAxeRuleStatuses]
  )

  const handleRequestFailure = React.useCallback(() => {
    navigate!('/organizations')
  }, [navigate])

  React.useEffect(() => {
    if (error) {
      handleRequestFailure()
    }
  }, [error, handleRequestFailure])

  const project: ProjectContainerQuery['project'] | undefined = data?.project

  if (!project || aqFeatureFlagsLoading) {
    return <LoaderPage showLoaders />
  }

  const familyId1 = getProjectAnalyticsFamilyId(project.id, 1)
  const familyId2 = getProjectAnalyticsFamilyId(project.id, 2)

  return (
    <DocumentTitle title={project?.name || 'Cypress.io'}>
      <div className="project-container">
        <AQFeatureFlagsContext.Provider value={aqFeatureFlags}>
          <Router primary={false}>
            <chunks.ProjectRuns path="/runs" projectId={projectId} />
            <chunks.ProjectBranches path="/branches" projectId={projectId} />
            <chunks.BranchSummary
              path="/branches/:branchName/*"
              projectId={projectId}
            />
            <RunContainer path="/runs/:runId/*" project={project} />
            <chunks.ProjectSettings path="/settings/*" projectId={project.id} />
            <ProjectAnalytics path="/analytics">
              <AnalyticsGroupOneContainer
                path="/runs-over-time"
                projectId={project.id}
                project={project}
                component={RunsOverTimeAnalytic}
                familyId={familyId1}
              />
              <AnalyticsGroupOneContainer
                path="/run-duration"
                projectId={project.id}
                project={project}
                component={RunDurationAnalytic}
                familyId={familyId1}
              />
              <AnalyticsGroupOneContainer
                path="/run-duration-by-build"
                projectId={project.id}
                project={project}
                component={PastRunDurationPage}
                familyId={familyId1}
              />
              <AnalyticsGroupOneContainer
                path="/test-suite-size"
                projectId={project.id}
                project={project}
                component={TestSuiteSizeAnalytic}
                familyId={familyId1}
              />
              <AnalyticsGroupTwoContainer
                path="/top-failures/*topFailuresParams"
                projectId={project.id}
                project={project}
                component={TopFailuresAnalytic}
                familyId={familyId2}
                orgId={project.organizationInfo.id}
              />
              <AnalyticsGroupTwoContainer
                path="/slowest-tests/*slowestTestsParams"
                projectId={project.id}
                project={project}
                component={SlowestTestsAnalytic}
                familyId={familyId2}
                orgId={project.organizationInfo.id}
              />
              <AnalyticsGroupTwoContainer
                path="/most-common-errors"
                projectId={project.id}
                project={project}
                component={MostCommonErrorsAnalytic}
                familyId={familyId2}
                orgId={project.organizationInfo.id}
              />
              <AnalyticsGroupTwoContainer
                path="/flaky-tests/*flakyTestsParams"
                projectId={project.id}
                project={project}
                component={FlakyTestsAnalytic}
                familyId={familyId2}
                orgId={project.organizationInfo.id}
              />
            </ProjectAnalytics>
            <NotFound default />
          </Router>
        </AQFeatureFlagsContext.Provider>
      </div>
    </DocumentTitle>
  )
}

export default observer(ProjectContainer)
