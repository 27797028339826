import { palette } from '@frontend/design-system'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { Toast } from '~/common/toast/Toast'

import store from './store'

@observer
export default class AppNotification extends Component {
  render() {
    if (!store.active) return null

    // FIXME: This is overly-fussy, but trying to pass a custom component
    // through the store doesn't seem to work
    const actionColor =
      store.variant === 'failure' && store.action
        ? palette.red500
        : palette.gray300

    const onClick = () => {
      this._clearNotification()
    }

    return (
      <Toast
        actionStyle={{ color: actionColor }}
        active={store.active}
        icon={store.icon}
        id={store.id}
        message={store.message}
        onDismiss={onClick}
        variant={store.variant}
      />
    )
  }

  _clearNotification() {
    return store.clearNotification()
  }
}
