import { LoaderPage } from '~/common/loader'
import * as React from 'react'
import Loadable from 'react-loadable'

import { ErrorBoundaryContent } from './lib/error-boundary'

export const lazy = <T extends React.ComponentType<any>>(
  loader: () => Promise<T | { default: T }>,
  shouldShowLoader: boolean = true
) => {
  return Loadable({
    delay: 200,
    loader,
    loading: (props) => {
      if (props.error) {
        window.Sentry.captureException(props.error)
        return <ErrorBoundaryContent />
      }
      if (shouldShowLoader) {
        return <LoaderPage showLoaders={props.pastDelay} />
      }
      return <div />
    },
  })
}

export const chunks = {
  Login: lazy(() =>
    import(/* webpackChunkName: 'login' */ './login/AuthLogin').then(
      (mod) => mod.AuthLogin
    )
  ),
  Signup: lazy(() =>
    import(/* webpackChunkName: 'signup' */ './login/AuthSignup').then(
      (mod) => mod.AuthSignup
    )
  ),
  ResetPassword: lazy(() =>
    import(/* webpackChunkName: 'signup' */ './auth/ResetPassword').then(
      (mod) => mod.ResetPassword
    )
  ),
  OrgsList: lazy(() =>
    import(/* webpackChunkName: 'orgs-vNext' */ './orgs/list/OrgsList').then(
      (mod) => mod.default
    )
  ),
  CreateOrg: lazy(() =>
    import(/* webpackChunkName: 'org-create-vNext' */ './orgs/CreateOrg').then(
      (mod) => mod.default
    )
  ),
  InviteTeam: lazy(() =>
    import(/* webpackChunkName: 'org-invite-vNext' */ './orgs/InviteTeam').then(
      (mod) => mod.default
    )
  ),
  UserJourneySurvey: lazy(() =>
    import(
      /* webpackChunkName: 'org-invite-vNext' */ './orgs/UserJourneySurvey'
    ).then((mod) => mod.UserJourneySurvey)
  ),
  OrganizationEnterpriseReporting: lazy(() =>
    import(
      /* webpackChunkName: 'org-invite-vNext' */ './orgs/EnterpriseReportingOrg'
    ).then((mod) => mod.EnterpriseReportingOrg)
  ),
  ProjectsList: lazy(() =>
    import(
      /* webpackChunkName: 'org-projects-vNext' */ './projects/ProjectsList'
    ).then((mod) => mod.default)
  ),
  ProjectRuns: lazy(
    () => import(/* webpackChunkName: 'runs-vNext' */ './runs/Runs')
  ),
  ProjectBranches: lazy(
    () =>
      import(
        /* webpackChunkName: 'org-project-branches' */ './branches/BranchesList'
      )
  ),
  BranchSummary: lazy(
    () =>
      import(
        /* webpackChunkName: 'org-project-branch-summary' */ './branches/BranchSummary/BranchSummary'
      )
  ),
  ProjectSettings: lazy(() =>
    import(
      /* webpackChunkName: 'project-settings-vNext' */ './project-settings/ProjectSettings'
    ).then((mod) => mod.ProjectSettings)
  ),
  TrialProgress: lazy(
    () =>
      import(
        /* webpackChunkName: 'trial-progress' */ './onboarding/TrialProgress'
      )
  ),
  OrgSettings: lazy(
    () =>
      import(
        /* webpackChunkName: 'org-settings-v2' */ './orgs/settings/OrgSettings'
      )
  ),
  OrgIntegrations: lazy(() =>
    import(
      /* webpackChunkName: 'org-integrations' */ './integrations/integrations'
    ).then((mod) => mod.Integrations)
  ),
  GithubIntegrationOrganization: lazy(
    () =>
      import(
        /* webpackChunkName: 'github-integration-organization' */ './github-integration/GithubIntegrationOrganizationPage'
      )
  ),
  GithubEnterpriseInstallationForm: lazy(
    () =>
      import(
        /* webpackChunkName: 'github-enterprise-form' */ './github-integration/GithubEnterpriseInstallationForm'
      )
  ),
  GitlabIntegrationOrganization: lazy(
    () =>
      import(
        /* webpackChunkName: 'gitlab-integration-organization' */ './integrations/gitlab-integration/GitlabIntegrationOrganizationPage'
      )
  ),
  GitLabForEnterpriseIntegrationOrganization: lazy(
    () =>
      import(
        /* webpackChunkName: 'gitlab-enterprise-integration-organization' */ './integrations/gitlab-integration/GitlabIntegrationOrganizationPage'
      )
  ),
  JiraIntegrationOrganization: lazy(
    () =>
      import(
        /* webpackChunkName: 'jira-integration-organization' */ './integrations/jira-integration/JiraIntegrationOrganizationPage'
      )
  ),
  JiraIntegrationOrganizationSelection: lazy(
    () =>
      import(
        /* webpackChunkName: 'jira-integration-organization-selection' */ './integrations/jira-integration/JiraIntegrationOrganizationSelection/JiraIntegrationOrganizationSelection'
      )
  ),
  SlackIntegrationOrganization: lazy(
    () =>
      import(
        /* webpackChunkName: 'slack-integration-organization' */ './integrations/slack-integration/OrganizationSlackIntegration'
      )
  ),
  SlackIntegrationOrganizationSelection: lazy(
    () =>
      import(
        /* webpackChunkName: 'slack-integration-organization-selection' */ './integrations/slack-integration/SlackIntegrationOrganizationSelection/SlackIntegrationOrganizationSelection'
      )
  ),
  BitbucketIntegrationOrganization: lazy(
    () =>
      import(
        /* webpackChunkName: 'bitbucket-integration-organization' */ './integrations/bitbucket-integration/BitbucketIntegrationOrganization'
      )
  ),
  SSOIntegrationOrganization: lazy(
    () =>
      import(
        /* webpackChunkName: 'sso-integration-organization' */ './integrations/SSOIntegration/SSOIntegration'
      )
  ),
  MSTeamsIntegrationOrganization: lazy(
    () =>
      import(
        /* webpackChunkName: 'ms-teams-integration-organization' */ './integrations/ms-teams-integration/MSTeamsIntegrationOrganizationPage'
      )
  ),
  OrgMembers: lazy(
    () =>
      import(
        /* webpackChunkName: 'org-users-vNext' */ './users/OrgUsersAndTeams'
      )
  ),
  OrgTeams: lazy(
    () =>
      import(
        /* webpackChunkName: 'org-teams-vNext' */ './users/OrgUsersAndTeams'
      )
  ),
  BillingAndUsage: lazy(
    () =>
      import(/* webpackChunkName: 'org-billing' */ './billing/BillingAndUsage')
  ),
  Pricing: lazy(
    () => import(/* webpackChunkName: 'org-billing' */ './billing/Pricing')
  ),
  RunOverview: lazy(
    () => import(/* webpackChunkName: 'specs' */ './overview/RunOverview'),
    false
  ),
  Errors: lazy(
    () => import(/* webpackChunkName: 'specs' */ './errors/RunOverviewErrors'),
    false
  ),
  Specs: lazy(
    () => import(/* webpackChunkName: 'specs' */ './specs/SpecsList'),
    false
  ),
  TestResults: lazy(
    () =>
      import(
        /* webpackChunkName: 'test-results' */ './test-results/RunTestResults'
      ),
    false
  ),
  AccessibilityRouter: lazy(
    () =>
      import(
        /* webpackChunkName: 'accessibility' */ './accessibility/AccessibilityRouter'
      ),
    false
  ),
  InteractivityRouter: lazy(
    () =>
      import(
        /* webpackChunkName: 'interactivity' */ './interactivity/InteractivityRouter'
      ),
    false
  ),
  Properties: lazy(
    () =>
      import(/* webpackChunkName 'Properties' */ './properties/PropertiesTab')
  ),
  TermsOfUse: lazy(
    () => import(/* webpackChunkName: 'terms' */ './static-pages/terms-of-use')
  ),
  NotFound: lazy(() => import(/* webpackChunkName: '404' */ './not-found')),
  Welcome: lazy(
    () => import(/* webpackChunkName: 'Welcome' */ '~/profile/Welcome')
  ),
  Verify: lazy(() => import(/* webpackChunkName: 'Verify' */ '~/auth/Verify')),
  Profile: lazy(
    () => import(/* webpackChunkName: 'Profile' */ '~/profile/Profile')
  ),
  MembershipsInvite: lazy(
    () =>
      import(
        /* webpackChunkName: 'accept-invite' */ './memberships/AcceptInvite'
      )
  ),
  TestReplayFrame: lazy(
    () =>
      import(
        /* webpackChunkName: 'test-replay-container' */ './test-replay-frame/TestReplayFrame'
      ),
    false
  ),
}
