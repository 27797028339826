import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import DocumentTitle from 'react-document-title'
import {
  AnalyticsViewByFilter,
  formatDateRangeDate,
  formatDateRangeDateISO,
  getValuesFromMultiSelectOptions,
} from '~/common/filters'
import {
  FlakyRateEmptyState,
  shouldShowFlakyRateEmptyState,
} from '~/common/flaky-rate-empty-state/FlakyRateEmptyState'
import {
  LatestFlakinessFragment,
  TestResultScreenshotFragment,
  useProjectFlakyTestsQuery,
  ProjectFlakyTestsQuery,
  useProjectFlakyTestsKpiQuery,
  ViewByEnum,
  TimeRangeInput,
  ProjectContainerQuery,
} from '~/graphql-codegen-operations.gen'
import { FiltersContainer } from '~/common/filters/FiltersContainer'
import { StackedLayout } from '~/common/stacked-layout'
import { ErrorBoundaryContent } from '~/lib/error-boundary'
import { sendEventCustom } from '~/lib/page-events'
import { TestReplayModal } from '~/test-replay-frame/TestReplayModal'
import { downloadAnalyticsCSV, getAnalyticsCSVFileName } from '../analytics-api'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { FlakyTestsTable } from './FlakyTestsTable'
import Filters from '../filters/Filters'
import * as analytics from '~/data/projects/analytics/hooks'
import { DownloadCSV } from '../DownloadCSV'
import { DrillInDrawer } from '~/common/drill-in-drawer/DrillInDrawer'
import { TestAnalyticDrawerActionBar } from '~/project-analytics/drill-in/TestAnalyticDrawerActionBar'
import ScreenshotsLightbox from '~/test-results/screenshots/ScreenshotsLightbox'
import { VideoLightbox } from '~/videos/VideoLightbox'
import { getLightboxScreenshots } from '~/lib/utils-ts'
import { DrillInMostCommonErrors } from '../drill-in/MostCommonErrors/DrillInMostCommonErrors'
import { DrillInHistory } from '../drill-in/History/DrillInHistory'
import { useDrillIn } from '../drill-in/hooks'
import { DrillInLatestFlakiness } from './drill-in/FlakyTestsLatestFlakiness/FlakyTestsDrillInLatestFlakiness'
import { FlakyTestsChart } from './FlakyTestsChart'
import _ from 'lodash'
import { Kpis } from '../Kpis'
import { getKPIs } from './FlakyTestsKPI'
import { differenceInDays } from 'date-fns'
import { DrillInJiraIssue } from '../drill-in/JiraIssue/DrillInJiraIssue'
import { useTestReplayInAnalytics } from '~/data/projects/analytics/hooks'
import { features } from '~/lib/feature-flags'

type FlakyTestsAnalyticProps = {
  projectId: string
  project: ProjectContainerQuery['project']
  familyId: string
  orgId: string
}

const getShouldBeHourlyValue = (timeRange?: TimeRangeInput) => {
  const differenceDays = differenceInDays(
    formatDateRangeDate(timeRange?.endDate)!,
    formatDateRangeDate(timeRange?.startDate)!
  )

  return differenceDays < 2
}

const validViewByOptions = ['TEST_CASE', 'SPEC'] as ViewByEnum[]

export const FlakyTestsAnalytic: FunctionComponent<FlakyTestsAnalyticProps> = ({
  projectId,
  project,
  familyId,
  orgId,
}) => {
  const byBuildAnalytics =
    features.isEnabled('analytics-p1') &&
    features.isEnabled('analytics-p1-flaky-test')
  const [showJiraIssueModal, setShowJiraIssueModal] = useState(false)
  const [screenshots, setScreenshots] = useState<
    TestResultScreenshotFragment[] | null
  >(null)
  const [video, setVideo] = useState<LatestFlakinessFragment | null>(null)
  const { replay, setReplay } = useTestReplayInAnalytics()

  const [flakyTestsAnalytics, setFlakyTestsAnalytics] =
    useState<ProjectFlakyTestsQuery>()
  const { selected: viewBy, setSelected: onViewBySelect } =
    analytics.useViewByFilter(familyId)
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const { selected: tags, matchType: tagsMatch } =
    analytics.useTagsFilter(familyId)
  const { selected: specFiles } = analytics.useSpecFilesFilter(familyId)
  const { selected: runGroups } = analytics.useRunGroupsFilter(familyId)
  const { selected: browsers } = analytics.useBrowsersFilter(familyId)
  const { selected: cypressVersions } =
    analytics.useCypressVersionsFilter(familyId)
  const { selected: operatingSystems } =
    analytics.useOperatingSystemsFilter(familyId)
  const { flakyTests, setFlakyTests } = analytics.useFlakyTests()
  const { selected: chartFilter, setSelected: setChartFilter } =
    analytics.useChartFlakyTestsFilter(familyId)
  const [shouldBeHourly, setShouldBeHourly] = useState(
    getShouldBeHourlyValue(timeRange)
  )

  analytics.useEventTracking('flaky-tests', 'Flaky tests', familyId)

  useEffect(() => {
    setShouldBeHourly(getShouldBeHourlyValue(timeRange))
  }, [timeRange])

  useEffect(() => {
    if (!validViewByOptions.includes(viewBy)) {
      onViewBySelect('TEST_CASE')
    }
  }, [onViewBySelect, viewBy])

  const validViewBy = !validViewByOptions.includes(viewBy)
    ? 'TEST_CASE'
    : viewBy

  // Don't add the tagsMatch parameter if there is no tags parameter.
  const tagsMatchOrUndefined = getValuesFromMultiSelectOptions(tags || [])
    ? tagsMatch
    : undefined

  const {
    data,
    loading: queryRunning,
    error,
  } = useProjectFlakyTestsQuery({
    variables: {
      id: projectId,
      input: {
        projectId,
        viewBy: validViewBy,
        timeRange: {
          startDate: formatDateRangeDateISO(timeRange?.startDate),
          endDate: formatDateRangeDateISO(timeRange?.endDate),
        },
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        specs: getValuesFromMultiSelectOptions(specFiles || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
        timeInterval: shouldBeHourly ? 'HOUR' : 'DAY',
        intervalType: byBuildAnalytics ? 'BUILD' : 'TIME',
      },
    },
  })

  const kpiTimeRange = React.useMemo<
    [Date | undefined, Date | undefined]
  >(() => {
    return !byBuildAnalytics
      ? [
          chartFilter?.[0] || timeRange?.startDate,
          chartFilter?.[1] || timeRange?.endDate,
        ]
      : [timeRange?.startDate, timeRange?.endDate]
  }, [byBuildAnalytics, chartFilter, timeRange])
  const { data: kpiData, loading: kpiRunning } = useProjectFlakyTestsKpiQuery({
    variables: {
      input: {
        projectId,
        viewBy: validViewBy,
        timeRange: {
          startDate: formatDateRangeDateISO(kpiTimeRange[0]),
          endDate: formatDateRangeDateISO(kpiTimeRange[1]),
        },
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        specs: getValuesFromMultiSelectOptions(specFiles || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
        intervalType: byBuildAnalytics ? 'BUILD' : 'TIME',
      },
    },
  })

  const {
    activeDrawerId,
    testInfo,
    analyticRef,
    specHash,
    titleHash,
    onCloseDrawer,
  } = useDrillIn({
    projectId,
    viewBy: validViewBy,
    path: 'flaky-tests',
    nodes: data?.metrics.projectFlakinessTestsOverTime.nodes,
  })

  useEffect(() => {
    if (!queryRunning && data) {
      if (flakyTests !== undefined && !_.isEqual(data, flakyTests)) {
        setChartFilter(undefined)
      }
      setFlakyTests(data)
    }
  }, [data, queryRunning, setFlakyTests, setChartFilter, flakyTests])

  useEffect(() => {
    if (
      chartFilter?.length &&
      flakyTests?.metrics.projectFlakinessTestsOverTime.nodes.length &&
      flakyTests.metrics.projectFlakinessTestsOverTime.chartNodes.length
    ) {
      const [chartFilterMin, chartFilterMax] = chartFilter

      const indexMin = !byBuildAnalytics
        ? _.findIndex(
            flakyTests.metrics.projectFlakinessTestsOverTime.chartNodes,
            ['date', moment.utc(chartFilterMin).toISOString()]
          )
        : (chartFilterMin as number)
      const indexMax = !byBuildAnalytics
        ? _.findIndex(
            flakyTests.metrics.projectFlakinessTestsOverTime.chartNodes,
            ['date', moment.utc(chartFilterMax).toISOString()]
          )
        : (chartFilterMax as number)

      const filteredChartNodes =
        flakyTests.metrics.projectFlakinessTestsOverTime.chartNodes.slice(
          indexMin,
          indexMax > -1 ? indexMax : undefined
        )
      const filteredIds = _.flatten(_.map(filteredChartNodes, 'ids'))

      if (filteredIds) {
        const filteredNodes = _.filter(
          flakyTests.metrics.projectFlakinessTestsOverTime.nodes,
          (node) => filteredIds.includes(node.id)
        )

        setFlakyTestsAnalytics({
          ...flakyTests,
          metrics: {
            projectFlakinessTestsOverTime: {
              chartNodes:
                flakyTests.metrics.projectFlakinessTestsOverTime.chartNodes,
              nodes: filteredNodes,
            },
          },
        })
      } else {
        setFlakyTestsAnalytics(flakyTests)
      }
    } else {
      setFlakyTestsAnalytics(flakyTests)
    }
  }, [byBuildAnalytics, chartFilter, flakyTests])

  const handleChartSelect = useCallback(
    (minDate: Date | number, maxDate: Date | number) => {
      if (
        chartFilter?.length &&
        moment(chartFilter[0]).toISOString() ===
          moment(minDate).toISOString() &&
        moment(chartFilter[1]).toISOString() === moment(maxDate).toISOString()
      ) {
        setChartFilter([])
      } else {
        setChartFilter([minDate, maxDate])
      }
    },
    [chartFilter, setChartFilter]
  )

  async function downloadCSV() {
    const fileName = getAnalyticsCSVFileName('flaky-tests', timeRange)
    const response = await downloadAnalyticsCSV({
      id: projectId,
      type: 'flaky-tests',
      params: {
        viewBy: validViewBy,
        startDate: formatDateRangeDateISO(timeRange?.startDate),
        endDate: formatDateRangeDateISO(timeRange?.endDate),
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        specs: getValuesFromMultiSelectOptions(specFiles || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
      },
    })
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Export', {
      analyticID: 'flaky-tests',
      label: 'Flaky tests',
    })

    if (!window.Cypress) {
      /* istanbul ignore next */
      fileDownload(response, `${fileName}.csv`)
    }
  }

  const isLoading = queryRunning || error !== undefined
  const kpis = getKPIs(
    kpiData,
    kpiRunning,
    flakyTestsAnalytics,
    queryRunning,
    validViewBy
  )

  return (
    <DocumentTitle title="Flaky Tests">
      <StackedLayout title="Flaky tests" className="no-content-padding">
        <div className="project-analytics" ref={analyticRef}>
          <div className="flaky-tests-analytic analytic">
            {(() => {
              if (
                flakyTests?.project &&
                shouldShowFlakyRateEmptyState({ project: flakyTests.project })
              ) {
                return <FlakyRateEmptyState project={flakyTests.project} />
              }

              return (
                <>
                  <FiltersContainer
                    viewBy={
                      <AnalyticsViewByFilter
                        id="analytics-view-by"
                        value={validViewBy}
                        onSelect={onViewBySelect}
                        options={validViewByOptions}
                      />
                    }
                    extra={
                      <DownloadCSV
                        analytic="flaky-tests"
                        onClick={downloadCSV}
                      />
                    }
                  >
                    <Filters
                      familyId={familyId}
                      timeRange
                      branches
                      tags
                      specFiles
                      runGroups
                      browsers
                      cypressVersions
                      operatingSystems
                      viewerIsOwnerOrAdmin={
                        project.organizationInfo.viewerIsOwnerOrAdmin
                      }
                    />
                  </FiltersContainer>
                  {error ? (
                    <ErrorBoundaryContent />
                  ) : (
                    <>
                      <FlakyTestsChart
                        data={flakyTests}
                        isLoading={isLoading}
                        setChartFilter={handleChartSelect}
                        chartFilter={chartFilter}
                        shouldBeHourly={shouldBeHourly}
                        viewBy={validViewBy}
                        byBuildAnalytics={byBuildAnalytics}
                      />
                      <Kpis kpis={kpis} isLoading={isLoading} />
                      <FlakyTestsTable
                        data={flakyTestsAnalytics}
                        viewBy={validViewBy}
                        activeDrawerId={activeDrawerId}
                        isLoading={isLoading}
                      />
                      <DrillInDrawer
                        isOpen={Boolean(specHash && titleHash)}
                        onClose={onCloseDrawer}
                        testInfo={testInfo}
                      >
                        {specHash && titleHash ? (
                          <TestAnalyticDrawerActionBar
                            projectId={projectId}
                            specHash={specHash}
                            titleHash={titleHash}
                            onJiraIssueClick={() => setShowJiraIssueModal(true)}
                            orgId={orgId}
                          />
                        ) : null}
                        <DrillInJiraIssue
                          specHash={specHash}
                          titleHash={titleHash}
                          projectId={projectId}
                        />
                        <DrillInLatestFlakiness
                          specHash={specHash}
                          titleHash={titleHash}
                          familyId={familyId}
                          onScreenshotClick={setScreenshots}
                          onVideoClick={setVideo}
                          onReplayClick={setReplay}
                          projectId={projectId}
                        />
                        <DrillInMostCommonErrors
                          activeDrawerId={activeDrawerId}
                          familyId={familyId}
                          projectId={projectId}
                          specHash={specHash}
                          titleHash={titleHash}
                          testTitleParts={testInfo?.titleParts || []}
                          orgId={orgId}
                          showModal={showJiraIssueModal}
                          updateShowModal={setShowJiraIssueModal}
                          analyticsPath="flaky-tests"
                        />
                        <DrillInHistory
                          project={flakyTests?.project}
                          specHash={specHash}
                          titleHash={titleHash}
                          familyId={familyId}
                          projectId={projectId}
                        />
                      </DrillInDrawer>
                      {screenshots && screenshots.length > 0 && (
                        <ScreenshotsLightbox
                          isOpen
                          onClose={() => {
                            setScreenshots(null)
                          }}
                          allScreenshots={getLightboxScreenshots(screenshots)}
                          lightboxIndex={0}
                        />
                      )}
                      {video?.instance?.id && video.video && (
                        <VideoLightbox
                          instanceId={video.instance.id}
                          isOpen
                          onClose={() => setVideo(null)}
                          videoTimestamp={video.videoTimestamp}
                          videos={[
                            {
                              id: video.video.id,
                              url: video.video.videoUrl,
                            },
                          ]}
                        />
                      )}
                      {replay && (
                        <TestReplayModal
                          testUuid={replay.id}
                          run={replay.run}
                          spec={{
                            title: replay.instance.spec.basename,
                            group: replay.instance.group?.name ?? '',
                            os: replay.instance.os.name,
                            browser:
                              replay.instance.browser.formattedNameWithVersion,
                          }}
                          onHide={() => {
                            setReplay(null)
                          }}
                          hasPaidPlan={
                            replay.run.project.organization.plan.isPaid
                          }
                        />
                      )}
                    </>
                  )}
                </>
              )
            })()}
          </div>
        </div>
      </StackedLayout>
    </DocumentTitle>
  )
}
