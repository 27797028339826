import React, { FunctionComponent } from 'react'
import * as analytics from '~/data/projects/analytics/hooks'
import { RouteComponentProps } from '@reach/router'
import { ProjectContainerQuery } from '~/graphql-codegen-operations.gen'

type AnalyticsGroupOneContainerProps = {
  projectId: any
  project: ProjectContainerQuery['project']
  familyId: string
  component: any
} & RouteComponentProps

export const AnalyticsGroupOneContainer: FunctionComponent<
  AnalyticsGroupOneContainerProps
> = ({ projectId, project, familyId, component: Component }) => {
  // Init URL data importing, and Data Exporting to URL
  analytics.useSyncRouterWithFilter(familyId)
  analytics.useSyncFiltersWithRouter(familyId)

  // Filter Search Setup
  analytics.useBranchesSearch(projectId, familyId)
  analytics.useTagsSearch(projectId, familyId)
  analytics.useTagsEditor(projectId, familyId)
  analytics.useRunGroupsSearch(projectId, familyId)
  analytics.useCommittersSearch(projectId, familyId)

  analytics.useProjectAnalyticsFilterOptionsInitialization({
    projectId,
    familyId,
  })
  const { filterDefaultValuesInitialized, filtersInitialized } =
    analytics.useProjectAnalyticsFilterDefaultsInitialization({
      projectId,
      familyId,
    })

  return filterDefaultValuesInitialized && filtersInitialized ? (
    <Component projectId={projectId} project={project} familyId={familyId} />
  ) : null
}
