import cs from 'clsx'
import Prism from 'prismjs'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Tooltip } from '@frontend/design-system/src/components/Tooltip'
import ButtonDesign from '@cypress-design/react-button'
import { useCopyText } from '../../lib/hooks/useCopyText'
import {
  IconDocumentBlank,
  IconArrowExpand,
  IconGeneralClipboard,
} from '@cypress-design/react-icon'
import { ContentLoader } from '../loader/ContentLoader'
import { getContainedSelection } from './CodeSnippet.utils'

Prism.plugins.NormalizeWhitespace?.setDefaults({
  'remove-trailing': true,
  'remove-indent': true,
  'left-trim': true,
  'right-trim': true,
  'tabs-to-spaces': 2,
})

type CodeSnippetProps = {
  code: string
  shouldHideLineNumbers?: boolean
}

// Legacy styling and layout for code snippets
export const CodeSnippet: FunctionComponent<CodeSnippetProps> = ({
  code,
  shouldHideLineNumbers = false,
}) => {
  useLayoutEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <pre
      className={cs('code-snippet', {
        'line-numbers': !shouldHideLineNumbers,
      })}
    >
      <code className="language-diff-javascript diff-highlight">{code}</code>
    </pre>
  )
}

export type DiffType = 'added' | 'none'

type CodeSnippetV2 = {
  code: string
  copyText: string
  withCopyBtn?: boolean
  copyBtnPendo?: string
  overlayDefaultText?: string
  fileName?: string
  variant?: 'design-system-candidate' | 'test-replay' | 'test-generation' // if this enters DS, plz update accordingly,
  language?: 'javascript' | 'yml' | 'markup'
  collapsed?: boolean
  iconOnlyCopyBtn?: boolean
  overlayClassName?: string
  diffRows?: DiffType[]
  truncateFileName?: boolean
  onCopy?: (copiedText: string) => void
}

export const CopyCodeButton = ({
  copyText,
  overlayDefaultText = 'Copy code',
  copyBtnPendo,
  iconOnlyCopyBtn = false,
  overlayClassName = '',
  onCopy,
}: {
  copyText: string
  overlayDefaultText: string
  copyBtnPendo?: string
  iconOnlyCopyBtn: boolean
  overlayClassName?: string
  onCopy?: (copiedText: string) => void
}) => {
  const {
    onCopy: didCopy,
    copyState,
    resetText,
  } = useCopyText(overlayDefaultText)

  return (
    <Tooltip
      overlayClassName={overlayClassName}
      placement="top"
      overlay={<p style={{ marginBottom: -10 }}>{copyState.copyText}</p>}
      onVisibleChange={resetText}
    >
      <div className="copy-btn-container">
        <CopyToClipboard
          text={copyText}
          onCopy={(copiedText) => {
            didCopy()
            onCopy?.(copiedText)
          }}
        >
          <div className="code-snippet-button-wrapper">
            <ButtonDesign
              className="code-snippet-copy-btn"
              data-pendo={copyBtnPendo}
              data-cy="code-snippet-copy-btn"
              aria-label="Copy code to clipboard"
            >
              <IconGeneralClipboard
                className="copy-icon"
                name="general-clipboard"
              />{' '}
              {!iconOnlyCopyBtn && <span>Copy!</span>}
            </ButtonDesign>
          </div>
        </CopyToClipboard>
      </div>
    </Tooltip>
  )
}

const Loading = ({ numLines }: { numLines: number }) => {
  const height = 12
  const radius = height / 2
  const pillWidth = 22
  const gap = 16
  // masks the content loader so it matches the design but the shimmer
  // is shared between the different visible parts
  const path = `M${radius},0
    a${radius},${radius} 0 1,0 0,${height}
    L${pillWidth - radius},${height}
    a${radius},${radius} 0 1,0 0,-${height}
    L${radius},0
    M${pillWidth + radius + gap},0
    a${radius},${radius} 0 1,0 0,${height}
    M${pillWidth + radius + gap},${height}
    L1000,${height}
    a${radius},${radius} 0 1,0 0,-${height}
    L${pillWidth + radius + gap},0`.replace(/\s+/g, ' ')
  const clipPath = `path("${path}")`

  return (
    <>
      {Array.from({ length: numLines }).map((_, index) => (
        <div
          key={index}
          data-cy="code-snippet-loading-line"
          className="w-full p-[8px]"
        >
          <div className="flex w-full h-[16px]" style={{ clipPath }}>
            <ContentLoader className="flex-1 rounded-full" />
          </div>
        </div>
      ))}
    </>
  )
}

// Next-gen styling and layout for code snippets
export const CodeSnippetV2: FunctionComponent<
  CodeSnippetV2 & { numLoadingLines?: number }
> = ({
  code,
  copyText,
  withCopyBtn,
  copyBtnPendo,
  overlayDefaultText = 'Copy code',
  variant = '',
  fileName,
  language = 'javascript',
  collapsed,
  iconOnlyCopyBtn = false,
  overlayClassName,
  diffRows,
  numLoadingLines = 0,
  truncateFileName = false,
  onCopy,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed)
  const [codeSnippet, setCodeSnippet] = useState(code)
  const [showExpand, setShowExpand] = useState(false)

  useEffect(() => {
    if (isCollapsed) {
      const splitLine = 5
      const splitCode = code.split(/\r\n|\r|\n/)

      setShowExpand(splitCode.length > splitLine)
      setCodeSnippet(splitCode.slice(0, splitLine).join('\r\n'))
    } else {
      setCodeSnippet(code)
    }
  }, [code, isCollapsed])

  const numRows = React.useMemo(() => {
    const codeHeight = codeSnippet.split(/\r\n|\r|\n/).length
    const rowNumbers: JSX.Element[] = []
    for (let i = 0; i < codeHeight; i++) {
      const num = i + 1
      rowNumbers.push(
        <div key={num} className="row-num">
          {num}
        </div>
      )
    }
    return rowNumbers
  }, [codeSnippet])

  const diffColumn = React.useMemo(() => {
    if (!diffRows || !diffRows.length) return null

    return (
      <div className="col-diff">
        {diffRows.map((diffType, index) => {
          return <div key={index} className={`row-diff row-diff-${diffType}`} />
        })}
      </div>
    )
  }, [diffRows])

  const isLoading = numLoadingLines > 0

  const copyButton = withCopyBtn && !isLoading && (
    <div className="col-copy-btn" style={{ marginTop: -2 }}>
      <CopyCodeButton
        iconOnlyCopyBtn={iconOnlyCopyBtn}
        copyText={copyText}
        copyBtnPendo={copyBtnPendo}
        overlayDefaultText={overlayDefaultText}
        overlayClassName={overlayClassName}
        onCopy={onCopy}
      />
    </div>
  )

  useLayoutEffect(() => {
    if (!isLoading) {
      Prism.highlightAll()
    }
  }, [codeSnippet, isLoading])

  const codeEl = React.createRef<HTMLElement>()

  const onCopyEvent = useCallback(() => {
    const selection = window.getSelection()
    const codeElement = codeEl.current

    // Make sure we have a handle on the code block element
    // If not it probably hasn't rendered yet (loading state)
    if (!codeElement) {
      return
    }
    // Parse the copy event to capture only the content being copied that lies within
    // the code block. If the selection is entirely outside the code block this will
    // come back as an empty string
    const containedSelectedText = getContainedSelection(selection, codeElement)
    onCopy?.(containedSelectedText)
  }, [onCopy, codeEl])

  useEffect(() => {
    // Register a copy event listener against the top-level document
    // We want to check copy events that occur against any element
    // since they could *contain* portions of our code block
    document.addEventListener('copy', onCopyEvent)
    // Deregister the event listener as cleanup
    return () => document.removeEventListener('copy', onCopyEvent)
  }, [onCopyEvent])

  return (
    <div
      className={cs('code-snippet', variant, {
        loading: isLoading,
      })}
      data-cy="code-snippet"
    >
      {fileName && (
        <div
          className={cs('code-snippet-file-name', {
            truncate: truncateFileName,
          })}
        >
          <div data-cy="file-name" className="w-full">
            <div className="flex w-full justify-between">
              <div className="flex items-center min-w-[0] mr-[10px]">
                <IconDocumentBlank
                  className="shrink-0"
                  size="16"
                  strokeColor="gray-500"
                  fillColor="gray-100"
                />
                <div className="flex min-w-[0] max-w-[100%]">
                  <div
                    className={cs('h-[26px]', {
                      [`truncate h-[37px]`]: truncateFileName,
                    })}
                  >
                    {/*
                      Use `bdo` to get overflow ellipsis to take effect at start of line rather than end.
                      CSS version of this has a bug that moves trailing '/' to start of string
                    */}
                    <bdo
                      role="none"
                      dir="rtl"
                      className="w-full truncate inline-block"
                      title={fileName}
                    >
                      <bdo role="none" dir="ltr">
                        {fileName}
                      </bdo>
                    </bdo>
                  </div>
                </div>
              </div>
              {copyButton}
            </div>
          </div>
        </div>
      )}
      <div className="code-snippet-container-v2">
        {isLoading ? (
          <Loading numLines={numLoadingLines} />
        ) : (
          <>
            {diffColumn}
            <div className="col-nums">{numRows}</div>
            <div className="col-code">
              <pre className="code-snippet-v2">
                <code className={`language-${language}`} ref={codeEl}>
                  {codeSnippet}
                </code>
              </pre>
              {showExpand && isCollapsed && (
                <div className="code-expand">
                  <ButtonDesign
                    onClick={() => setIsCollapsed(false)}
                    className="expand-btn"
                    variant="outline-light"
                  >
                    <IconArrowExpand className="expand-icon" /> Expand
                  </ButtonDesign>
                </div>
              )}
            </div>
            {!fileName && copyButton}
          </>
        )}
      </div>
    </div>
  )
}

export const CodeSnippetError = ({
  frame,
  line,
  language,
}: {
  frame: string
  line: number | null
  language: string | null
}) => {
  const codeFrame = useRef(null)
  const [isHighlighted, setIsHighlighted] = useState(false)

  useLayoutEffect(() => {
    if (codeFrame.current && !isHighlighted) {
      setIsHighlighted(true)
      Prism.highlightAllUnder(codeFrame.current)
    }
  }, [isHighlighted])

  const highlightLine = Math.min(line ?? 0, 3)

  return (
    <div className="code-snippet-error">
      <pre
        ref={codeFrame}
        data-line={highlightLine}
        className="no-whitespace-normalization"
      >
        <code className={`code-frame language-${language || 'text'}`}>
          {frame}
        </code>
      </pre>
    </div>
  )
}
