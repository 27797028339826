import { DropdownButton, DropdownButtonProps } from '@frontend/design-system'
import React, { FunctionComponent, ReactNode } from 'react'
import cs from 'clsx'

type FilterProps = {
  id: string
  title?: ReactNode
  open?: boolean
  onToggle?: DropdownButtonProps['onToggle']
  renderSelectedLabel: () => ReactNode
  renderDropDown: () => ReactNode
  className?: string
  disabled?: boolean
}

export const Filter: FunctionComponent<FilterProps> = ({
  id,
  title,
  open,
  renderDropDown,
  onToggle,
  renderSelectedLabel,
  className,
  disabled,
}) => {
  return (
    <DropdownButton
      open={open}
      className={cs('filter', className)}
      onToggle={onToggle}
      id={`filter-${id}`}
      data-pendo={`${id}-filter`}
      data-cy={`${id}-filter`}
      bsStyle="default"
      bsSize="sm"
      noCaret
      title={
        <>
          <span className="filter__icon">{title}</span> {renderSelectedLabel()}
          <svg
            className="filter__dropdown-caret"
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.47559 0.979004L5.47559 4.979L9.47559 0.979004"
              stroke="#3386D4"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </>
      }
      disabled={disabled}
    >
      {renderDropDown()}
    </DropdownButton>
  )
}
