import UilTag from '@iconscout/react-unicons/icons/uil-tag'
import {
  Button,
  FilterEmptyState,
  Icon,
  OverlayTrigger,
  Popover,
  Radio,
  Tag,
  Label,
} from '@frontend/design-system'
import React, { FunctionComponent, ReactNode } from 'react'
import Highlighter from 'react-highlight-words'
import { components } from 'react-select'
import _ from 'lodash'

import { ColorPickerPopover } from '~/common/color-picker-popover'
import { MultiSelectFilter } from '~/common/filters'
import { MultiSelectOptionType, TagEditorInfo } from '~/common/filters/types'
import { TagsMatchEnum } from '~/graphql-codegen-operations.gen'

type ProjectTagsFilterProps = {
  id: string
  state: ProjectTagsFilterStateProp
  disableEdit?: boolean
}

type ProjectTagsMultiSelectOptionType = MultiSelectOptionType<
  string,
  { color: string }
>

type ProjectTagsFilterStateProp = {
  search: string | undefined
  loading: boolean
  editing: boolean
  editorInfo: TagEditorInfo | undefined
  options: ProjectTagsMultiSelectOptionType[] | undefined
  selected: ProjectTagsMultiSelectOptionType[] | undefined
  matchType: TagsMatchEnum | undefined
  setSearch: (value: string) => void
  setLoading: (value: boolean) => void
  setEditing: (value: boolean) => void
  setSelected: (value: any[]) => void
  setEditorInfo: (value: TagEditorInfo) => void
  setMatchType: (value: TagsMatchEnum) => void
}

function renderLabel(
  option: MultiSelectOptionType<string, { color: string }>
): string {
  const label = (
    <Tag backgroundColor={option.labelProperties!.color}>{option.label}</Tag>
  ) as unknown

  return label as string
}

export const ProjectTagsFilterGlobal: FunctionComponent<ProjectTagsFilterProps> =
  React.memo(({ id, state, disableEdit }) => {
    const {
      options = [],
      search,
      setSearch,
      loading,
      setLoading,
      editing,
      setEditing,
      selected = [],
      setSelected,
      setEditorInfo,
      matchType,
      setMatchType,
    } = state

    // Sort Tags to match selected + unselected
    const tagOptions: MultiSelectOptionType[] = _.unionBy(
      selected,
      options
        ? options.map((option) => {
            return {
              ...option,
              // Option selection is disabled, if we are editing that option.
              disabled: editing,
              suggested: false,
            }
          })
        : [],
      'value'
    )

    const onClickClear = () => setSelected([])
    const onInputChange = () => setLoading(true)

    const unselectedItems: MultiSelectOptionType[] = _.difference(
      tagOptions,
      selected || []
    )

    const setEditingState = (value) => () => setEditing(value)
    const Footer = () => {
      return (
        <div className="project-tags-filter--footer">
          {editing ? (
            <Button bsStyle="link" onClick={setEditingState(false)}>
              Done editing
            </Button>
          ) : (
            <Button
              bsStyle="link"
              onClick={setEditingState(true)}
              disabled={disableEdit === true}
            >
              Edit tags
            </Button>
          )}
        </div>
      )
    }

    const onSelectColor = (tag: string, color: string): void => {
      setEditorInfo({ tag, color })
    }

    const onSelectFilterMatchOption = (matchType: TagsMatchEnum): void => {
      setMatchType(matchType)

      setSelected(selected)
    }

    const FilterOptionsHeader: FunctionComponent = () => {
      return <div className="tags-filter--header h6">Filter Options</div>
    }

    const SelectedItemsHeader: FunctionComponent = () => {
      return (
        <div className="tags-filter--header h6">
          Selected
          <Button bsStyle="link" onClick={onClickClear}>
            Clear
          </Button>
        </div>
      )
    }

    const UnselectedItemsHeader: FunctionComponent = () => {
      if (search) {
        return (
          <div className="tags-filter--header unselected h6">
            Search results
          </div>
        )
      }

      return (
        <div className="tags-filter--header unselected h6">Recently used</div>
      )
    }

    const customOption = (props): ReactNode => {
      if (editing) {
        return (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose
            overlay={
              <Popover id="color-picker-popover">
                <ColorPickerPopover
                  onSelect={(color) => onSelectColor(props.data.label, color)}
                />
              </Popover>
            }
          >
            <div className="project-tags-filter--editing-option select__option">
              {props.data.label}
              <div
                className="color-picker-color-sample pull-right"
                style={{ backgroundColor: props.data.labelProperties.color }}
              />
            </div>
          </OverlayTrigger>
        )
      }

      return (
        <>
          {selected.length > 1 && props.value === selected[0].value && (
            <>
              <FilterOptionsHeader />

              <div className="select__option">
                <Radio
                  isChecked={matchType === 'ANY'}
                  label="Match Any"
                  onChange={() => onSelectFilterMatchOption('ANY')}
                  value="matchAny"
                />
              </div>
              <div className="select__option">
                <Radio
                  isChecked={matchType === 'ALL'}
                  label="Match All"
                  onChange={() => onSelectFilterMatchOption('ALL')}
                  value="matchAll"
                />
              </div>
            </>
          )}
          {selected.length > 0 && props.value === selected[0].value && (
            <SelectedItemsHeader />
          )}
          {unselectedItems.length > 0 &&
            unselectedItems[0].value === props.value && (
              <UnselectedItemsHeader />
            )}
          <components.Option {...props} key={`view-${props.data.label}`}>
            <span>
              {props.isSelected ? (
                <Icon
                  name="check-square"
                  className="filter__checkbox fa-lg primary"
                />
              ) : (
                <Icon name="square-o" className="filter__checkbox fa-lg" />
              )}{' '}
              <Tag backgroundColor={props.data.labelProperties.color}>
                <Highlighter
                  searchWords={[search]}
                  textToHighlight={props.data.label}
                />
              </Tag>{' '}
            </span>
            {props.data.suggested && <Label bsStyle="default">default</Label>}
          </components.Option>
        </>
      )
    }

    const NoTagsMessage = () => {
      return (
        <FilterEmptyState
          filter="tag"
          icon={<UilTag />}
          info="Find runs more quickly by adding tags."
          searchQuery={search}
          url="http://on.cypress.io/run-tags"
        />
      )
    }

    const label = 'Tag: '
    const emptyLabel = `${label}Any`
    const renderSelectedLabel = (selected): React.ReactNode => {
      const selectedOption = selected[0]
      return (
        <span>
          {label}
          <Tag backgroundColor={selectedOption.labelProperties!.color}>
            {selectedOption.label}
          </Tag>
          {selected.length > 1 && <span>{` +${selected.length - 1}`}</span>}
        </span>
      )
    }

    return (
      <div
        data-cy="tags-filter"
        data-pendo="tags-filter"
        className="tags-filter multi-select-filter-with-categories"
      >
        <MultiSelectFilter
          id={id}
          title={<UilTag />}
          emptyLabel={emptyLabel}
          dropdownClassName="project-tags-filter"
          placeholder="Search for a tag..."
          customOption={customOption}
          getOptionLabel={renderLabel}
          isLoading={loading}
          keepOpen={editing}
          noOptionsMessage={NoTagsMessage}
          onChange={setSelected}
          onInputChange={onInputChange}
          onSearch={setSearch}
          options={tagOptions}
          selected={selected}
          renderSelectedLabel={renderSelectedLabel}
          footer={<Footer />}
        />
      </div>
    )
  })
