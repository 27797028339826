import { IconTechnologyServer } from '@cypress-design/react-icon'
import {
  Button,
  FilterEmptyState,
  Icon,
  Label,
  Tag,
} from '@frontend/design-system'
import _ from 'lodash'
import React, { FunctionComponent, ReactNode, useState, useEffect } from 'react'
import Highlighter from 'react-highlight-words'
import { components } from 'react-select'

import { MultiSelectFilter } from '~/common/filters'
import { MultiSelectOptionType } from '~/common/filters/types'
import { useProjectRunGroupsQuery } from '~/graphql-codegen-operations.gen'
import iconStyles from '~/common/icon/module.ReactIcon.scss'

type ProjectRunGroupsFilterProps = {
  id: string
  onChange: any
  projectId: string
  selected: MultiSelectOptionType<string>[]
  shouldRefetch?: boolean
  disabled?: boolean
}

const RunGroupIcon = () => {
  return (
    <IconTechnologyServer
      size="16"
      fillColor="gray-50"
      strokeColor="gray-500"
      className={iconStyles.strokeWidthThin}
    />
  )
}

export const ProjectRunGroupsFilter: FunctionComponent<ProjectRunGroupsFilterProps> =
  React.memo(
    ({ id, onChange, projectId, selected, shouldRefetch, disabled }) => {
      const [runGroupsSearch, setRunGroupsSearch] = useState('')
      const [isLoading, updateIsLoading] = useState(false)

      const { data, refetch } = useProjectRunGroupsQuery({
        variables: {
          id: projectId,
          runGroupsSearch,
        },
        onCompleted() {
          updateIsLoading(false)
        },
      })

      useEffect(() => {
        if (shouldRefetch) {
          refetch()
        }
      }, [refetch, shouldRefetch])

      let runGroupOptions: MultiSelectOptionType[] = data
        ? data.project.runGroups.nodes.map((runGroup) => {
            return {
              label: runGroup.name,
              suggested: false,
              value: runGroup.name,
            }
          })
        : []

      runGroupOptions = _.unionBy(selected, runGroupOptions, 'value')

      const unselectedItems: MultiSelectOptionType[] = _.difference(
        runGroupOptions,
        selected
      )

      const SelectedItemsHeader: FunctionComponent = () => {
        return (
          <div className="run-groups-filter--header h6">
            Selected
            <Button bsStyle="link" onClick={() => onChange([])}>
              Clear
            </Button>
          </div>
        )
      }

      const UnselectedItemsHeader: FunctionComponent = () => {
        if (runGroupsSearch) {
          return (
            <div className="run-groups-filter--header unselected h6">
              Search results
            </div>
          )
        }

        return (
          <div className="run-groups-filter--header unselected h6">
            Recently used
          </div>
        )
      }

      const customOption = (props): ReactNode => {
        return (
          <>
            {selected.length > 0 && props.value === selected[0].value && (
              <SelectedItemsHeader />
            )}
            {unselectedItems.length > 0 &&
              unselectedItems[0].value === props.value && (
                <UnselectedItemsHeader />
              )}
            <components.Option {...props}>
              <span>
                {props.isSelected ? (
                  <Icon
                    name="check-square"
                    className="filter__checkbox fa-lg primary"
                  />
                ) : (
                  <Icon name="square-o" className="filter__checkbox fa-lg" />
                )}{' '}
                <Tag backgroundColor={'#f3f4fa'}>
                  <Highlighter
                    searchWords={[runGroupsSearch]}
                    textToHighlight={props.data.label}
                  />
                </Tag>{' '}
              </span>
              {props.data.suggested && <Label bsStyle="default">default</Label>}
            </components.Option>
          </>
        )
      }

      const NoRunGroupsMessage = () => {
        return (
          <FilterEmptyState
            filter="runGroup"
            icon={<RunGroupIcon />}
            info="Find runs more quickly by adding run groups."
            searchQuery={runGroupsSearch}
            url="http://on.cypress.io/run-runGroups"
          />
        )
      }

      const label = 'Run Group: '
      const emptyLabel = `${label}Any`
      const renderSelectedLabel = (selected): React.ReactNode => {
        return (
          <span>
            {label}
            {selected[0].label}
            {selected.length > 1 && <span>{` +${selected.length - 1}`}</span>}
          </span>
        )
      }

      return (
        <div
          data-cy="run-groups-filter"
          data-pendo="run-groups-filter"
          className="run-groups-filter multi-select-filter-with-categories"
        >
          <MultiSelectFilter
            id={id}
            title={<RunGroupIcon />}
            emptyLabel={emptyLabel}
            dropdownClassName="project-run-groups-filter"
            placeholder="Search for a run group..."
            customOption={customOption}
            isLoading={isLoading}
            noOptionsMessage={NoRunGroupsMessage}
            onChange={(
              runGroups: MultiSelectOptionType<string, { color: string }>[]
            ) => {
              onChange(runGroups)
            }}
            onInputChange={() => updateIsLoading(true)}
            onSearch={setRunGroupsSearch}
            options={runGroupOptions}
            selected={selected}
            renderSelectedLabel={renderSelectedLabel}
            disabled={disabled}
          />
        </div>
      )
    }
  )
