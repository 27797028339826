import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import DocumentTitle from 'react-document-title'
import {
  AnalyticsViewByFilter,
  formatDateRangeDateISO,
  getValuesFromMultiSelectOptions,
} from '~/common/filters'
import {
  useProjectTopFailuresQuery,
  LatestFailureFragment,
  ProjectTopFailuresQuery,
  TestResultScreenshotFragment,
  ProjectContainerQuery,
} from '~/graphql-codegen-operations.gen'
import { DrillInDrawer } from '~/common/drill-in-drawer/DrillInDrawer'
import { FiltersContainer } from '~/common/filters/FiltersContainer'
import { StackedLayout } from '~/common/stacked-layout'
import { ErrorBoundaryContent } from '~/lib/error-boundary'
import { sendEventCustom } from '~/lib/page-events'
import { getLightboxScreenshots } from '~/lib/utils-ts'
import { TestReplayModal } from '~/test-replay-frame/TestReplayModal'
import { downloadAnalyticsCSV, getAnalyticsCSVFileName } from '../analytics-api'
import fileDownload from 'js-file-download'
import { TopFailuresTable } from './TopFailuresTable'
import Filters from '../filters/Filters'
import * as analytics from '~/data/projects/analytics/hooks'
import { DownloadCSV } from '../DownloadCSV'
import { Kpis } from '../Kpis'
import { getKPIs } from './TopFailuresKPI'
import { TopFailuresChart } from './TopFailuresChart'
import ScreenshotsLightbox from '~/test-results/screenshots/ScreenshotsLightbox'
import { VideoLightbox } from '~/videos/VideoLightbox'
import _ from 'lodash'
import { DrillInHistory } from '../drill-in/History/DrillInHistory'
import { DrillInMostCommonErrors } from '../drill-in/MostCommonErrors/DrillInMostCommonErrors'
import { TestAnalyticDrawerActionBar } from '../drill-in/TestAnalyticDrawerActionBar'
import { useDrillIn } from '../drill-in/hooks'
import { DrillInLatestFailures } from './drill-in/TopFailuresLatestFailures/TopFailuresDrillInLatestFailures'
import { DrillInJiraIssue } from '../drill-in/JiraIssue/DrillInJiraIssue'
import { useTestReplayInAnalytics } from '~/data/projects/analytics/hooks'

type TopFailuresAnalyticProps = {
  projectId: string
  project: ProjectContainerQuery['project']
  familyId: string
  orgId: string
}

export const TopFailuresAnalytic: FunctionComponent<
  TopFailuresAnalyticProps
> = ({ projectId, project, familyId, orgId }) => {
  const [showJiraIssueModal, setShowJiraIssueModal] = useState(false)
  const [screenshots, setScreenshots] = useState<
    TestResultScreenshotFragment[] | null
  >(null)
  const [video, setVideo] = useState<LatestFailureFragment | null>(null)
  const { replay, setReplay } = useTestReplayInAnalytics()

  const [topFailuresAnalytics, setTopFailuresAnalytics] =
    useState<ProjectTopFailuresQuery>()
  const { selected: viewBy, setSelected: onViewBySelect } =
    analytics.useViewByFilter(familyId)
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const { selected: tags, matchType: tagsMatch } =
    analytics.useTagsFilter(familyId)
  const { selected: specFiles } = analytics.useSpecFilesFilter(familyId)
  const { selected: runGroups } = analytics.useRunGroupsFilter(familyId)
  const { selected: browsers } = analytics.useBrowsersFilter(familyId)
  const { selected: committers } = analytics.useCommittersFilter(familyId)
  const { selected: flaky } = analytics.useFlakyFilter(familyId)
  const { selected: cypressVersions } =
    analytics.useCypressVersionsFilter(familyId)
  const { selected: operatingSystems } =
    analytics.useOperatingSystemsFilter(familyId)
  const { selected: chartRange, setSelected: setChartRange } =
    analytics.useChartRangeTopFailuresFilter(familyId)

  const { topFailures, setTopFailures } = analytics.useTopFailures()

  analytics.useEventTracking('top-failures', 'Top failures', familyId)

  // Don't add the tagsMatch parameter if there is no tags parameter.
  const tagsMatchOrUndefined = getValuesFromMultiSelectOptions(tags || [])
    ? tagsMatch
    : undefined

  const {
    data,
    loading: queryRunning,
    error,
  } = useProjectTopFailuresQuery({
    variables: {
      id: projectId,
      input: {
        projectId,
        viewBy,
        timeRange: {
          startDate: formatDateRangeDateISO(timeRange?.startDate),
          endDate: formatDateRangeDateISO(timeRange?.endDate),
        },
        flakyTests: getValuesFromMultiSelectOptions(flaky || []),
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        specs: getValuesFromMultiSelectOptions(specFiles || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        committers: getValuesFromMultiSelectOptions(committers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
      },
    },
  })

  const {
    testInfo,
    analyticRef,
    activeDrawerId,
    specHash,
    titleHash,
    onCloseDrawer,
  } = useDrillIn({
    projectId,
    viewBy,
    path: 'top-failures',
    nodes: data?.metrics.projectTopFailingTestsOverTime.nodes,
  })

  useEffect(() => {
    if (!queryRunning && data) {
      if (topFailures !== undefined && !_.isEqual(data, topFailures)) {
        setChartRange([])
      }
      setTopFailures(data)
    }
  }, [data, queryRunning, setTopFailures, setChartRange, topFailures])

  useEffect(() => {
    if (
      chartRange?.length &&
      topFailures?.metrics.projectTopFailingTestsOverTime.nodes.length
    ) {
      const [chartRangeMin, chartRangeMax] = chartRange

      const filteredNodes = _.filter(
        topFailures.metrics.projectTopFailingTestsOverTime.nodes,
        (node) => {
          if (_.isNil(node.failureRate)) {
            return false
          }

          if (chartRangeMin === 0) {
            return node.failureRate <= chartRangeMax
          }

          return (
            node.failureRate > chartRangeMin &&
            node.failureRate <= chartRangeMax
          )
        }
      )

      setTopFailuresAnalytics({
        ...topFailures,
        metrics: {
          projectTopFailingTestsOverTime: {
            nodes: filteredNodes,
          },
        },
      })
    } else {
      setTopFailuresAnalytics(topFailures)
    }
  }, [chartRange, topFailures])

  const handleRangeSelect = useCallback(
    (chartRangeMin: number, chartRangeMax: number) => {
      if (
        chartRange?.length &&
        chartRange[0] === chartRangeMin &&
        chartRange[1] === chartRangeMax
      ) {
        setChartRange([])
      } else {
        setChartRange([chartRangeMin, chartRangeMax])
      }
    },
    [chartRange, setChartRange]
  )

  async function downloadCSV() {
    const fileName = getAnalyticsCSVFileName('top-failures', timeRange)
    const response = await downloadAnalyticsCSV({
      id: projectId,
      type: 'top-failures',
      params: {
        viewBy,
        flakyTests: getValuesFromMultiSelectOptions(flaky || []),
        startDate: formatDateRangeDateISO(timeRange?.startDate),
        endDate: formatDateRangeDateISO(timeRange?.endDate),
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        specs: getValuesFromMultiSelectOptions(specFiles || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        committers: getValuesFromMultiSelectOptions(committers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
      },
    })
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Export', {
      analyticID: 'top-failures',
      label: 'Top failures',
    })

    if (!window.Cypress) {
      /* istanbul ignore next */
      fileDownload(response, `${fileName}.csv`)
    }
  }

  const isLoading = queryRunning || error !== undefined
  const kpis = getKPIs(topFailuresAnalytics, queryRunning, viewBy, chartRange)

  return (
    <DocumentTitle title="Top Failures">
      <StackedLayout title="Top failures" className="no-content-padding">
        <div className="project-analytics" ref={analyticRef}>
          <div className="top-failures-analytic analytic">
            <FiltersContainer
              viewBy={
                <AnalyticsViewByFilter
                  id="analytics-view-by"
                  value={viewBy}
                  onSelect={onViewBySelect}
                />
              }
              extra={
                <DownloadCSV analytic="top-failures" onClick={downloadCSV} />
              }
            >
              <Filters
                familyId={familyId}
                branches
                tags
                flaky
                timeRange
                runGroups
                committers
                specFiles
                browsers
                cypressVersions
                operatingSystems
                viewerIsOwnerOrAdmin={
                  project.organizationInfo.viewerIsOwnerOrAdmin
                }
              />
            </FiltersContainer>
            {error ? (
              <ErrorBoundaryContent />
            ) : (
              <>
                <TopFailuresChart
                  data={topFailures}
                  isLoading={isLoading}
                  setChartRange={handleRangeSelect}
                  chartRange={chartRange}
                  viewBy={viewBy}
                />
                <Kpis kpis={kpis} isLoading={isLoading} />
                <TopFailuresTable
                  data={topFailuresAnalytics}
                  activeDrawerId={activeDrawerId}
                  viewBy={viewBy}
                  isLoading={isLoading}
                />
                <DrillInDrawer
                  isOpen={Boolean(specHash && titleHash)}
                  onClose={onCloseDrawer}
                  testInfo={testInfo}
                >
                  {specHash && titleHash ? (
                    <TestAnalyticDrawerActionBar
                      projectId={projectId}
                      specHash={specHash}
                      titleHash={titleHash}
                      onJiraIssueClick={() => setShowJiraIssueModal(true)}
                      orgId={orgId}
                    />
                  ) : null}
                  <DrillInJiraIssue
                    specHash={specHash}
                    titleHash={titleHash}
                    projectId={projectId}
                  />
                  <DrillInLatestFailures
                    specHash={specHash}
                    titleHash={titleHash}
                    familyId={familyId}
                    onScreenshotClick={setScreenshots}
                    onVideoClick={setVideo}
                    onReplayClick={setReplay}
                    projectId={projectId}
                  />
                  <DrillInMostCommonErrors
                    activeDrawerId={activeDrawerId}
                    familyId={familyId}
                    projectId={projectId}
                    specHash={specHash}
                    titleHash={titleHash}
                    testTitleParts={testInfo?.titleParts || []}
                    orgId={orgId}
                    showModal={showJiraIssueModal}
                    updateShowModal={setShowJiraIssueModal}
                    analyticsPath="top-failures"
                  />
                  <DrillInHistory
                    specHash={specHash}
                    titleHash={titleHash}
                    familyId={familyId}
                    project={topFailures?.project}
                    projectId={projectId}
                  />
                </DrillInDrawer>
                {screenshots && screenshots.length > 0 && (
                  <ScreenshotsLightbox
                    isOpen
                    onClose={() => {
                      setScreenshots(null)
                    }}
                    allScreenshots={getLightboxScreenshots(screenshots)}
                    lightboxIndex={0}
                  />
                )}
                {video?.instance?.id && video.video && (
                  <VideoLightbox
                    instanceId={video.instance.id}
                    isOpen
                    onClose={() => setVideo(null)}
                    videoTimestamp={video.videoTimestamp}
                    videos={[
                      {
                        id: video.video.id,
                        url: video.video.videoUrl,
                      },
                    ]}
                  />
                )}
                {replay && (
                  <TestReplayModal
                    testUuid={replay.id}
                    run={replay.run}
                    spec={{
                      title: replay.instance.spec.basename,
                      group: replay.instance.group?.name ?? '',
                      os: replay.instance.os.name,
                      browser: replay.instance.browser.formattedNameWithVersion,
                    }}
                    onHide={() => {
                      setReplay(null)
                    }}
                    hasPaidPlan={replay.run.project.organization.plan.isPaid}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </StackedLayout>
    </DocumentTitle>
  )
}
