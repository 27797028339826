import { IconActionDeleteXlarge } from '@cypress-design/react-icon'
import { palette } from '@frontend/design-system'
import _ from 'lodash'
import React, { ReactNode, useMemo } from 'react'
import { Notification } from 'react-notification'

interface ToastProps {
  actionStyle?: React.CSSProperties
  active: boolean
  barStyle?: React.CSSProperties
  icon?: ReactNode
  id?: string
  message: string
  onDismiss: () => void
  variant?: 'success' | 'failure'
}

export function Toast(props: ToastProps) {
  const options = useMemo(
    () =>
      _.defaults(props, {
        actionStyle: {},
        barStyle: {},
        id: _.uniqueId(),
        variant: 'success',
      }),
    [props]
  )

  if (!options.active) return null

  return (
    <Notification
      key={options.id}
      data-cy="toast"
      className={options.variant}
      barStyle={{
        font: '14px/1.5 sans-serif',
        background: palette.gray700,
        boxShadow: `0 3px 6px -3px rgba(33, 33, 33, 0.8)`,
        padding: 16,
        top: 112,
        bottom: null,
        right: 16,
        left: null,
        zIndex: 10000,
        ...options.barStyle,
      }}
      actionStyle={{
        font: '12px/21px sans-serif',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        color: palette.gray300,
        verticalAlign: 'baseline',
        display: 'inline-block',
        ...options.actionStyle,
      }}
      isActive
      message={
        <>
          {options.icon}
          <span data-cy="toast-message" className="notification-message">
            {options.message}
          </span>
        </>
      }
      dismissAfter={options.variant === 'failure' ? 20000 : 5000}
      /* @ts-ignore - typed as 'string' but can handle ReactNode */
      action={<IconActionDeleteXlarge alt="Close" />}
      onDismiss={options.onDismiss}
      onClick={options.onDismiss}
    />
  )
}
