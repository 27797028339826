import moment from 'moment'

export function calculateUsagePeriods({
  currentUsagePeriodStartsAt,
  currentUsagePeriodEndsAt,
  organizationCreatedAt,
  periodUnit,
  shouldIncludePartialPeriods = false,
}: {
  currentUsagePeriodStartsAt: string
  currentUsagePeriodEndsAt: string
  organizationCreatedAt: string
  periodUnit?: string | null
  shouldIncludePartialPeriods?: boolean
}): {
  startTime: moment.Moment
  endTime: moment.Moment
}[] {
  if (!currentUsagePeriodStartsAt) return []

  // If no periodUnit default to month.
  periodUnit = periodUnit || 'month'

  // Calculate whether our input ends at the end of the month.
  const inputEndsAt = moment.utc(currentUsagePeriodEndsAt)
  const isEndOfMonth = inputEndsAt
    .clone()
    .milliseconds(0)
    .isSame(inputEndsAt.clone().endOf('month').milliseconds(0), 'second')

  const n = periodUnit === 'year' ? 2 : 6
  const usagePeriods: {
    startTime: moment.Moment
    endTime: moment.Moment
  }[] = []

  const startTime = moment.utc(currentUsagePeriodStartsAt)
  const endTime = moment.utc(currentUsagePeriodEndsAt)

  for (let i = 0; i < n; i++) {
    if (endTime.isBefore(organizationCreatedAt)) {
      break
    }

    const unit = periodUnit === 'year' ? 'year' : 'month'

    const getEndTime = () =>
      isEndOfMonth ? endTime.clone().endOf('month') : endTime.clone()

    if (!shouldIncludePartialPeriods) {
      usagePeriods.push({
        startTime: startTime.clone(),
        endTime: getEndTime(),
      })
    } else if (i === 0) {
      // For first period, use actual start/end times
      usagePeriods.push({
        startTime: startTime.clone(),
        endTime: getEndTime(),
      })

      // For monthly periods, add partial period if start time is not beginning of month
      const isPartialMonthStart =
        periodUnit === 'month' &&
        !startTime.isSame(startTime.clone().startOf('month'), 'day')

      if (isPartialMonthStart) {
        usagePeriods.push({
          startTime: startTime.clone().startOf('month'),
          endTime: startTime.clone().subtract(1, 'millisecond'),
        })
      }
    } else {
      // For subsequent periods, use whole months
      usagePeriods.push({
        startTime: startTime.clone().startOf('month'),
        endTime: endTime.clone().endOf('month'),
      })
    }

    startTime.subtract(1, unit)
    endTime.subtract(1, unit)
  }

  return usagePeriods
}
