import _ from 'lodash'

function getContainedRange(
  originalRange: Range,
  targetElement: HTMLElement
): Range | null {
  try {
    // If the entire range is already within the target element return the original range
    if (
      targetElement.contains(originalRange.startContainer) &&
      targetElement.contains(originalRange.endContainer)
    ) {
      return originalRange
    }

    // It's possible the selection range can *bound* our target element without actually containing
    // any content from our target element. Fun!
    if (!originalRange.intersectsNode(targetElement)) {
      return null
    }

    // Start with the existing start of the range
    let startContainer = originalRange.startContainer
    let startOffset = originalRange.startOffset

    // If start is outside target element, adjust to target element's start
    // We can safely assume it's "before" our target element due to the earlier intersect check
    if (!targetElement.contains(startContainer)) {
      startContainer = targetElement
      startOffset = 0
    }

    // Start with the existing end of the range
    let endContainer = originalRange.endContainer
    let endOffset = originalRange.endOffset

    // If end is outside target element, adjust to target element's end
    // We can safely assume it's "after" our target element due to the earlier intersect check
    if (!targetElement.contains(endContainer)) {
      endContainer = targetElement
      endOffset = targetElement.childNodes.length
    }

    // Create a new range to store the contained portion
    const containedRange = targetElement.ownerDocument.createRange()

    // Set the new range with the contained portion
    containedRange.setStart(startContainer, startOffset)
    containedRange.setEnd(endContainer, endOffset)
    return containedRange
  } catch (error) {
    return null
  }
}

/**
 * Find the portion of a {@link Selection} that lies within a target element. If the selection
 * is nil, empty, or exists entirely outside the target element the result will be an empty string.
 */
export function getContainedSelection(
  selection: Selection | null,
  targetElement: HTMLElement
): string {
  if (!selection || !selection.rangeCount) {
    return ''
  }

  // In practice there will only ever be one selection range, but technically multiple ranges
  // could be selected using scripting tools. While unlikely we'll support by concatenating
  // all selected ranges since it could be an a11y tool or other helper script
  return _.range(0, selection.rangeCount)
    .map((idx) => selection.getRangeAt(idx))
    .map((range) => getContainedRange(range, targetElement))
    .map((range) => range?.toString() || '')
    .join('')
}
