import {
  ProjectContainerQuery,
  useProjectPastBuildRunAnalyticsQuery,
} from '~/graphql-codegen-operations.gen'
import { useNavigate } from '@reach/router'
import React, { FunctionComponent, useCallback, useMemo } from 'react'
import DocumentTitle from 'react-document-title'
import {
  formatDateRangeDateISO,
  getValuesFromMultiSelectOptions,
} from '~/common/filters'
import { FiltersContainer } from '~/common/filters/FiltersContainer'
import { StackedLayout } from '~/common/stacked-layout'
import { ErrorBoundaryContent } from '~/lib/error-boundary'
import { Kpis } from '../Kpis'
import Filters from '../filters/Filters'
import * as analytics from '~/data/projects/analytics/hooks'
import { PastRunDurationChart } from './PastRunDurationChart'
import { PastRunDurationTable } from './PastRunDurationTable'
import { getKPIs } from './getKPIs'

type PastRunDurationPageProps = {
  projectId: string
  project: ProjectContainerQuery['project']
  familyId: string
}

const ANALYTICS_KEY: string = 'past-builds-run-duration'
export const PastRunDurationPage: FunctionComponent<
  PastRunDurationPageProps
> = ({ projectId, project, familyId }) => {
  const navigate = useNavigate()
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { selected: tags } = analytics.useTagsFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)

  analytics.useEventTracking(ANALYTICS_KEY, 'Run duration by build', familyId)
  const {
    data,
    loading: queryRunning,
    error,
  } = useProjectPastBuildRunAnalyticsQuery({
    variables: {
      projectId,
      input: {
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        timeRange: {
          startDate: formatDateRangeDateISO(timeRange?.startDate),
          endDate: formatDateRangeDateISO(timeRange?.endDate),
        },
        perPage: 100,
      },
    },
  })

  const isLoading = queryRunning || error !== undefined
  const kpis = useMemo(() => getKPIs(data, queryRunning), [data, queryRunning])
  const navigateToRun = useCallback(
    (buildNumber: number) => {
      navigate(`/projects/${projectId}/runs/${buildNumber}`)
    },
    [projectId, navigate]
  )

  if (error) {
    return (
      <DocumentTitle title="Past Builds - Run duration">
        <StackedLayout
          title="Past Builds Run duration"
          className="no-content-padding"
        >
          <ErrorBoundaryContent />
        </StackedLayout>
      </DocumentTitle>
    )
  }

  return (
    <DocumentTitle title="Past Builds - Run duration">
      <StackedLayout
        title="Past Builds Run duration"
        className="no-content-padding"
      >
        <div className="project-analytics">
          <div className="past-run-duration analytic">
            <FiltersContainer>
              <Filters
                familyId={familyId}
                branches
                tags
                timeRange
                viewerIsOwnerOrAdmin={
                  project.organizationInfo.viewerIsOwnerOrAdmin
                }
              />
            </FiltersContainer>
            {error ? (
              <ErrorBoundaryContent />
            ) : (
              <>
                <PastRunDurationChart
                  data={data}
                  isLoading={isLoading}
                  navigateToRun={navigateToRun}
                />
                <Kpis kpis={kpis} isLoading={isLoading} />
                <PastRunDurationTable data={data} isLoading={isLoading} />
              </>
            )}
          </div>
        </div>
      </StackedLayout>
    </DocumentTitle>
  )
}
