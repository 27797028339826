import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import DocumentTitle from 'react-document-title'
import {
  AnalyticsViewByFilter,
  formatDateRangeDateISO,
  getValuesFromMultiSelectOptions,
} from '~/common/filters'
import {
  useProjectSlowestTestsQuery,
  ProjectSlowestTestsQuery,
  ProjectContainerQuery,
} from '~/graphql-codegen-operations.gen'
import { FiltersContainer } from '~/common/filters/FiltersContainer'
import { StackedLayout } from '~/common/stacked-layout'
import { ErrorBoundaryContent } from '~/lib/error-boundary'
import { useLocation } from '~/lib/hooks/useLocation'
import { sendEventCustom } from '~/lib/page-events'
import { downloadAnalyticsCSV, getAnalyticsCSVFileName } from '../analytics-api'
import fileDownload from 'js-file-download'
import { SlowestTestsTable } from './SlowestTestsTable'
import Filters from '../filters/Filters'
import * as analytics from '~/data/projects/analytics/hooks'
import { DownloadCSV } from '../DownloadCSV'
import { Kpis } from '../Kpis'
import { getKPIs } from './SlowestTestsKPI'
import { SlowestTestsChart } from './SlowestTestsChart'
import _ from 'lodash'

type SlowestTestsAnalyticProps = {
  projectId: string
  project: ProjectContainerQuery['project']
  familyId: string
}

export const SlowestTestsAnalytic: FunctionComponent<
  SlowestTestsAnalyticProps
> = ({ projectId, project, familyId }) => {
  const { location } = useLocation()
  const [activeDrawerId, setActiveDrawerId] = useState('')

  const [slowestTestsAnalytics, setSlowestTestsAnalytics] =
    useState<ProjectSlowestTestsQuery>()
  const { selected: viewBy, setSelected: onViewBySelect } =
    analytics.useViewByFilter(familyId)
  const { selected: branches } = analytics.useBranchesFilter(familyId)
  const { timeRange } = analytics.useTimeRangeFilter(familyId)
  const { selected: tags, matchType: tagsMatch } =
    analytics.useTagsFilter(familyId)
  const { selected: specFiles } = analytics.useSpecFilesFilter(familyId)
  const { selected: runGroups } = analytics.useRunGroupsFilter(familyId)
  const { selected: browsers } = analytics.useBrowsersFilter(familyId)
  const { selected: committers } = analytics.useCommittersFilter(familyId)
  const { selected: cypressVersions } =
    analytics.useCypressVersionsFilter(familyId)
  const { selected: operatingSystems } =
    analytics.useOperatingSystemsFilter(familyId)
  const { selected: statuses } = analytics.useStatusFilter(familyId)
  const { selected: flaky } = analytics.useFlakyFilter(familyId)
  const { selected: chartRange, setSelected: setChartRange } =
    analytics.useChartRangeSlowestTestsFilter(familyId)

  const { slowestTests, setSlowestTests } = analytics.useSlowestTests()

  analytics.useEventTracking('slowest-tests', 'Slowest tests', familyId)

  // Don't add the tagsMatch parameter if there is no tags parameter.
  const tagsMatchOrUndefined = getValuesFromMultiSelectOptions(tags || [])
    ? tagsMatch
    : undefined

  const {
    data,
    loading: queryRunning,
    error,
  } = useProjectSlowestTestsQuery({
    variables: {
      id: projectId,
      input: {
        projectId,
        viewBy,
        timeRange: {
          startDate: formatDateRangeDateISO(timeRange?.startDate),
          endDate: formatDateRangeDateISO(timeRange?.endDate),
        },
        flakyTests: getValuesFromMultiSelectOptions(flaky || []),
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        tagsMatch: tagsMatchOrUndefined,
        specs: getValuesFromMultiSelectOptions(specFiles || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        committers: getValuesFromMultiSelectOptions(committers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
        statuses: getValuesFromMultiSelectOptions(statuses || []),
      },
    },
  })

  useEffect(() => {
    if (projectId) {
      const pathId = location.pathname.split(
        `/projects/${projectId}/analytics/slowest-tests/`
      )[1]

      const testResultId = pathId || ''

      if (viewBy !== 'TEST_CASE') {
        setActiveDrawerId('')
      } else if (activeDrawerId !== testResultId) {
        setActiveDrawerId(testResultId)
      }
    }
  }, [activeDrawerId, location.pathname, projectId, viewBy])

  useEffect(() => {
    if (!queryRunning && data) {
      if (slowestTests !== undefined && !_.isEqual(data, slowestTests)) {
        setChartRange([])
      }
      setSlowestTests(data)
    }
  }, [data, queryRunning, setSlowestTests, setChartRange, slowestTests])

  useEffect(() => {
    if (
      chartRange?.length &&
      slowestTests?.metrics.projectSlowestTests.nodes.length
    ) {
      const [chartRangeMin, chartRangeMax] = chartRange

      const filteredNodes = _.filter(
        slowestTests.metrics.projectSlowestTests.nodes,
        (node) => {
          if (_.isNil(node.medianDuration)) {
            return false
          }

          const isBelowThreshold = node.medianDuration <= chartRangeMax

          if (chartRangeMin === 0) {
            return isBelowThreshold
          }

          return node.medianDuration > chartRangeMin && isBelowThreshold
        }
      )

      setSlowestTestsAnalytics({
        ...slowestTests,
        metrics: {
          projectSlowestTests: {
            nodes: filteredNodes,
          },
        },
      })
    } else {
      setSlowestTestsAnalytics(slowestTests)
    }
  }, [chartRange, slowestTests])

  const handleRangeSelect = useCallback(
    (chartRangeMin: number, chartRangeMax: number) => {
      if (
        chartRange?.length &&
        chartRange[0] === chartRangeMin &&
        chartRange[1] === chartRangeMax
      ) {
        setChartRange([])
      } else {
        setChartRange([chartRangeMin, chartRangeMax])
      }
    },
    [chartRange, setChartRange]
  )

  async function downloadCSV() {
    const fileName = getAnalyticsCSVFileName('slowest-tests', timeRange)
    const response = await downloadAnalyticsCSV({
      id: projectId,
      type: 'slowest-tests',
      params: {
        viewBy,
        startDate: formatDateRangeDateISO(timeRange?.startDate),
        endDate: formatDateRangeDateISO(timeRange?.endDate),
        branches: getValuesFromMultiSelectOptions(branches || []),
        tags: getValuesFromMultiSelectOptions(tags || []),
        // Don't add the tagsMatch parameter if there is not tags parameter.
        tagsMatch: tagsMatchOrUndefined,
        specs: getValuesFromMultiSelectOptions(specFiles || []),
        flakyTests: getValuesFromMultiSelectOptions(flaky || []),
        runGroups: getValuesFromMultiSelectOptions(runGroups || []),
        browsers: getValuesFromMultiSelectOptions(browsers || []),
        committers: getValuesFromMultiSelectOptions(committers || []),
        cypressVersions: getValuesFromMultiSelectOptions(cypressVersions || []),
        oses: getValuesFromMultiSelectOptions(operatingSystems || []),
        statuses: getValuesFromMultiSelectOptions(statuses || []),
      },
    })
    /* istanbul ignore next */
    sendEventCustom('Analytics', 'Export', {
      analyticID: 'slowest-tests',
      label: 'Slowest tests',
    })

    if (!window.Cypress) {
      /* istanbul ignore next */
      fileDownload(response, `${fileName}.csv`)
    }
  }

  const isLoading = queryRunning || error !== undefined
  const kpis = getKPIs(slowestTestsAnalytics, queryRunning, viewBy, chartRange)

  return (
    <DocumentTitle title="Slowest Tests">
      <StackedLayout title="Slowest tests" className="no-content-padding">
        <div className="project-analytics">
          <div className="slowest-tests-analytic analytic">
            <FiltersContainer
              viewBy={
                <AnalyticsViewByFilter
                  id="analytics-view-by"
                  value={viewBy}
                  onSelect={onViewBySelect}
                />
              }
              extra={
                <DownloadCSV analytic="slowest-tests" onClick={downloadCSV} />
              }
            >
              <Filters
                familyId={familyId}
                branches
                tags
                flaky
                timeRange
                status
                runGroups
                committers
                specFiles
                browsers
                cypressVersions
                operatingSystems
                viewerIsOwnerOrAdmin={
                  project.organizationInfo.viewerIsOwnerOrAdmin
                }
              />
            </FiltersContainer>
            {error ? (
              <ErrorBoundaryContent />
            ) : (
              <>
                <SlowestTestsChart
                  data={slowestTests}
                  viewBy={viewBy}
                  isLoading={isLoading}
                  setChartRange={handleRangeSelect}
                  chartRange={chartRange}
                />
                <Kpis kpis={kpis} isLoading={isLoading} />
                <SlowestTestsTable
                  data={slowestTestsAnalytics}
                  viewBy={viewBy}
                  isLoading={isLoading}
                />
              </>
            )}
          </div>
        </div>
      </StackedLayout>
    </DocumentTitle>
  )
}
